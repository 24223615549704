import Vue from 'vue';
import Router, { Route, RouteConfig, Location } from 'vue-router';
import { auth } from '@/firebase';
import { idenSteps } from '@/helpers/identificationCheckout';
import { IdentCheckoutRouteNames, IdentificationPath, steps } from '@/helpers/checkout';
import { defaultLanguage, isValidLang, loadLanguageAsync, localStorageKey } from '@/i18n';
import moduleRoutes from '@/modules/routes';
import { collections } from '@/collections';
import { AuthComponents } from '@/store/models';
import { CheckoutStepNames, Step, IdentificationCheckoutStepNames } from '@/store/models/checkout';
import { clientConfig } from '@/helpers/clientData';
import Vuex from 'vuex';
import { PaymentProvider } from '@/store/models/investment';
import { WebViewMessageSelectedVueRouter } from '@/store/models/webview';
// Required before creating new store instance

Vue.use(Vuex);

Vue.use(Router);

const whitelabelConfig = clientConfig();

const internalClientName = whitelabelConfig.internalClientName;
const glossary = whitelabelConfig.glossary;
const details = whitelabelConfig.functionality.settings.details;
const settingsAutoReditect = whitelabelConfig.functionality.settings.settingsAutoReditect;
const landingRedirect = whitelabelConfig.landingRedirect;
const enabled2FA = whitelabelConfig.functionality.login.enabled2FA;
const paymentServiceProvider = whitelabelConfig.paymentServiceProvider;
const redirectToLoginIfNoUser = whitelabelConfig.redirectToLoginIfNoUser ?? false;

/**
 * Prefixing routes' paths to handle language
 */
const withPrefix = (prefix: string, routes: RouteConfig[]): RouteConfig[] =>
  routes.map((route): RouteConfig => {
    // Avoiding mutations
    const clonedRoute = { ...route };
    // Every route except for '/'
    if (clonedRoute.path !== '/') {
      clonedRoute.path = prefix + clonedRoute.path;
    }
    return clonedRoute;
  });

const mainRoutes: RouteConfig[] = [
  {
    path: '/',
    redirect: landingRedirect,
  },
  {
    path: '/login',
    component: (): Promise<object> =>
      import(/* webpackChunkName: "auth" */ '@/components/common/auth/auth-page/AuthPage.vue'),
    props: { initWithType: enabled2FA ? AuthComponents.Login2FA : AuthComponents.Login },
    meta: {
      requiresLogout: true,
    },
  },
  {
    path: '/checkout/investment/:id',
    name: 'checkoutInvestment',
    component: (): Promise<object> => import(/* webpackChunkName: "checkout" */ '@/components/checkout/Checkout.vue'),
    props: { initWithType: CheckoutStepNames.Investment },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/checkout/payment-methods/:id',
    component: (): Promise<object> => import(/* webpackChunkName: "checkout" */ '@/components/checkout/Checkout.vue'),
    props: { initWithType: CheckoutStepNames.PaymentMethods },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/checkout/status/:id/:paymentId',
    component: (): Promise<object> => import(/* webpackChunkName: "checkout" */ '@/components/checkout/Checkout.vue'),
    name: 'checkoutStatus',
    props: { initWithType: CheckoutStepNames.Status },
    meta: {
      requiresAuth: true,
    },
  },
  ...steps.reduce((routes: RouteConfig[], step: Step): RouteConfig[] => {
    let selectedRoutes = [...routes];
    if (step.name === CheckoutStepNames.Identification) {
      selectedRoutes = selectedRoutes.concat([
        {
          path: `/checkout/${IdentificationPath}/`,
          component: (): Promise<object> =>
            import(/* webpackChunkName: "checkout" */ '@/components/checkout/Checkout.vue'),
          props: { initWithType: CheckoutStepNames.Identification, header: false },
          meta: {
            requiresAuth: true,
          },
          children: [
            {
              path: ':investmentId/',
              name: IdentCheckoutRouteNames.MAIN,
              component: (): Promise<object> =>
                import(
                  /* webpackChunkName: "identification" */ '@/modules/identification/components/Identification.vue'
                ),
              props: { header: false },
              children: [
                {
                  path: 'idin/',
                  name: IdentCheckoutRouteNames.IDIN,
                  component: (): Promise<object> =>
                    import(
                      /* webpackChunkName: "identification" */ '@/modules/identification/components/idin/IdentificationIdin.vue'
                    ),
                  props: { header: false },
                },
                {
                  path: 'world/',
                  name: IdentCheckoutRouteNames.WORLD,
                  component: (): Promise<object> =>
                    import(
                      /* webpackChunkName: "identification" */ '@/modules/identification/components/world/IdentificationWorld.vue'
                    ),
                  props: { header: false },
                },
                {
                  path: 'business/',
                  name: IdentCheckoutRouteNames.BUSINESS,
                  component: (): Promise<object> =>
                    import(
                      /* webpackChunkName: "identification" */ '@/modules/identification/components/business/IdentificationBusiness.vue'
                    ),
                  props: { header: false },
                },
              ],
            },
          ],
        },
      ]);
    }
    if (step.name === CheckoutStepNames.Terms) {
      selectedRoutes = selectedRoutes.concat([
        {
          path: '/checkout/legal/:id',
          component: (): Promise<object> =>
            import(/* webpackChunkName: "checkout" */ '@/components/checkout/Checkout.vue'),
          props: { initWithType: CheckoutStepNames.Terms },
          meta: {
            requiresAuth: true,
          },
          name: CheckoutStepNames.Terms,
        },
      ]);
    }
    if (step.name === CheckoutStepNames.Questionnaire) {
      selectedRoutes = selectedRoutes.concat([
        {
          path: '/checkout/questionnaire/:id',
          component: (): Promise<object> =>
            import(/* webpackChunkName: "checkout" */ '@/components/checkout/Checkout.vue'),
          props: { initWithType: CheckoutStepNames.Questionnaire },
          meta: {
            requiresAuth: true,
          },
          name: CheckoutStepNames.Questionnaire,
        },
      ]);
    }
    if (step.name === CheckoutStepNames.RiskQuestionnaire) {
      selectedRoutes = selectedRoutes.concat([
        {
          path: '/checkout/riskquestionnaire/:id',
          component: (): Promise<object> =>
            import(/* webpackChunkName: "checkout" */ '@/components/checkout/Checkout.vue'),
          props: { initWithType: CheckoutStepNames.RiskQuestionnaire },
          meta: {
            requiresAuth: true,
          },
          name: CheckoutStepNames.RiskQuestionnaire,
        },
      ]);
    }
    if (step.name === CheckoutStepNames.Signature) {
      selectedRoutes = selectedRoutes.concat([
        {
          path: '/checkout/signature/:id',
          component: (): Promise<object> =>
            import(/* webpackChunkName: "checkout" */ '@/components/checkout/Checkout.vue'),
          props: { initWithType: CheckoutStepNames.Signature },
          meta: {
            requiresAuth: true,
          },
          name: CheckoutStepNames.Signature,
        },
      ]);
    }
    if (step.name === CheckoutStepNames.PaymentMethods) {
      selectedRoutes = selectedRoutes.concat([
        {
          path: '/checkout/payment-methods/:id',
          component: (): Promise<object> =>
            import(/* webpackChunkName: "checkout" */ '@/components/checkout/Checkout.vue'),
          props: { initWithType: CheckoutStepNames.PaymentMethods },
          meta: {
            requiresAuth: true,
          },
          name: CheckoutStepNames.PaymentMethods,
        },
      ]);
    }
    return selectedRoutes;
  }, []),
  ...idenSteps.reduce((routes: RouteConfig[], step: Step): RouteConfig[] => {
    let selectedRoutes = [...routes];
    if (step.name === IdentificationCheckoutStepNames.Identification) {
      selectedRoutes = selectedRoutes.concat([
        {
          path: `/identification-checkout/${IdentificationPath}/`,
          component: (): Promise<object> =>
            import(/* webpackChunkName: "checkout" */ '@/components/checkout/Checkout.vue'),
          props: { initWithType: CheckoutStepNames.Identification, header: false },
          meta: {
            requiresAuth: true,
            hasInvestment: false,
          },
          children: [
            {
              path: '',
              name: IdentCheckoutRouteNames.MAIN_WITHOUT_INVESTMENT,
              component: (): Promise<object> =>
                import(
                  /* webpackChunkName: "identification" */ '@/modules/identification/components/Identification.vue'
                ),
              props: { header: false },
              children: [
                {
                  path: 'idin/',
                  name: IdentCheckoutRouteNames.IDIN_WITHOUT_INVESTMENT,
                  component: (): Promise<object> =>
                    import(
                      /* webpackChunkName: "identification" */ '@/modules/identification/components/idin/IdentificationIdin.vue'
                    ),
                  props: { header: false },
                },
                {
                  path: 'world/',
                  name: IdentCheckoutRouteNames.WORLD_WITHOUT_INVESTMENT,
                  component: (): Promise<object> =>
                    import(
                      /* webpackChunkName: "identification" */ '@/modules/identification/components/world/IdentificationWorld.vue'
                    ),
                  props: { header: false },
                },
                {
                  path: 'business/',
                  name: IdentCheckoutRouteNames.BUSINESS_WITHOUT_INVESTMENT,
                  component: (): Promise<object> =>
                    import(
                      /* webpackChunkName: "identification" */ '@/modules/identification/components/business/IdentificationBusiness.vue'
                    ),
                  props: { header: false },
                },
              ],
            },
          ],
        },
      ]);
    }
    if (step.name === IdentificationCheckoutStepNames.Questionnaire) {
      selectedRoutes = selectedRoutes.concat([
        {
          path: '/identification-checkout/questionnaire/',
          component: (): Promise<object> =>
            import(/* webpackChunkName: "checkout" */ '@/components/checkout/Checkout.vue'),
          props: { initWithType: CheckoutStepNames.Questionnaire },
          meta: {
            requiresAuth: true,
            hasInvestment: false,
          },
          name: `IdentificationCheckout${IdentificationCheckoutStepNames.Questionnaire}`,
        },
      ]);
    }
    if (step.name === IdentificationCheckoutStepNames.RiskQuestionnaire) {
      selectedRoutes = selectedRoutes.concat([
        {
          path: '/identification-checkout/riskquestionnaire/',
          component: (): Promise<object> =>
            import(/* webpackChunkName: "checkout" */ '@/components/checkout/Checkout.vue'),
          props: { initWithType: CheckoutStepNames.RiskQuestionnaire },
          meta: {
            requiresAuth: true,
            hasInvestment: false,
          },
          name: `IdentificationCheckout${IdentificationCheckoutStepNames.RiskQuestionnaire}`,
        },
      ]);
    }
    return selectedRoutes;
  }, []),
  {
    path: '/register',
    component: (): Promise<object> =>
      import(/* webpackChunkName: "auth" */ '@/components/common/auth/auth-page/AuthPage.vue'),
    props: { initWithType: AuthComponents.Register },
    meta: {
      requiresLogout: true,
    },
  },
  {
    path: '/reset',
    component: (): Promise<object> =>
      import(/* webpackChunkName: "auth" */ '@/components/common/auth/auth-page/AuthPage.vue'),
    props: { initWithType: AuthComponents.Reset },
    meta: {
      requiresLogout: true,
    },
  },
  {
    path: '/auth-verification',
    component: (): Promise<object> =>
      import(/* webpackChunkName: "auth" */ '@/components/common/auth/auth-page/AuthPage.vue'),
    props: { initWithType: AuthComponents.AuthVerification },
    meta: {
      requiresLogout: true,
    },
  },
  {
    path: '/error/:errorType?',
    component: (): Promise<object> =>
      import(/* webpackChunkName: "error" */ '@/components/common/StaticErrors/StaticErrors.vue'),
  },
  {
    path: '/activate/:activateType?/:id?',
    component: (): Promise<object> =>
      import(/* webpackChunkName: "error" */ '@/components/common/activate/Activate.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/account',
    component: (): Promise<object> => import(/* webpackChunkName: "account" */ '@/components/account/Account.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'account',
        component: (): Promise<object> =>
          import(/* webpackChunkName: "account" */ '@/components/account/dashboard/AccountDashboard.vue'),
      },
      {
        path: 'settings',
        name: 'settings',
        component: (): Promise<object> =>
          import(/* webpackChunkName: "account" */ '@/components/account/settings/AccountSettings.vue'),
        redirect: `/account/settings/${settingsAutoReditect}`,
        children: [
          {
            path: 'details',
            name: 'settings-details',
            component: (): Promise<object> =>
              import(
                /* webpackChunkName: "account" */ `@/components/account/settings/details/account-settings-details-${
                  details === 'full' ? 'full' : 'standard'
                }/AccountSettingsDetails${details === 'full' ? 'Full' : 'Standard'}.vue`
              ),
          },
          {
            path: 'identification',
            name: 'settings-identification',
            component: (): Promise<object> =>
              import(
                /* webpackChunkName: "account" */ '@/components/account/settings/identification/AccountSettingsIdentification.vue'
              ),
          },
          {
            path: 'change',
            name: 'settings-change',
            component: (): Promise<object> =>
              import(
                /* webpackChunkName: "account" */ '@/components/account/settings/change/AccountSettingsChange.vue'
              ),
          },
          ...(paymentServiceProvider === PaymentProvider.OPP
            ? [
                {
                  path: 'opp',
                  name: 'opp',
                  component: (): Promise<object> =>
                    import(/* webpackChunkName: "account" */ '@/components/account/opp/AccountOpp.vue'),
                },
              ]
            : []),
        ],
      },
    ],
  },
  {
    path: '/all-campaigns',
    name: 'all-campaigns',
    component: (): Promise<object> =>
      import(/* webpackChunkName: "properties" */ '@/components/properties/Properties.vue'),
  },
  {
    path: '/all-campaigns/:id',
    name: 'all-campaigns-item',
    component: (): Promise<object> =>
      import(/* webpackChunkName: "property" */ '@/components/properties/property/wrapper/PropertyWrapper.vue'),
  },
  {
    path: '/all-campaigns/:id/:projectId',
    name: 'project',
    component: (): Promise<object> =>
      import(
        /* webpackChunkName: "property" */ '@/components/properties/property/variations/tabulated/project/ProjectDetail.vue'
      ),
  },
  // Legacy routes. For clients to still use the old properties routes and be redirected to the new routes
  {
    path: '/properties',
    redirect: { name: 'all-campaigns' },
  },
  {
    path: '/properties/:id',
    redirect: (to): Location => ({ name: 'all-campaigns-item', params: { id: to.params.id } }),
  },
  {
    path: '/property/:id',
    redirect: (to): Location => ({ name: 'all-campaigns-item', params: { id: to.params.id } }),
  },
  {
    path: '/properties/:id/:projectId',
    redirect: (to): Location => ({ name: 'project', params: { id: to.params.id, projectId: to.params.projectId } }),
  },
  {
    path: '/knowledge',
    component: (): Promise<object> =>
      import(/* webpackChunkName: "knowledge" */ '@/components/knowledge/Knowledge.vue'),
    redirect: '/knowledge/glossary',
    children: [
      {
        path: 'faq',
        name: 'knowledge-faq',
        component: (): Promise<object> =>
          import(
            /* webpackChunkName: "knowledge" */ `@/components/knowledge/faq/${internalClientName}/KnowledgeFaq.vue`
          ),
      },
      {
        path: 'glossary',
        name: 'knowledge-glossary',
        ...(glossary
          ? {
              component: (): Promise<object> =>
                import(/* webpackChunkName: "knowledge" */ '@/components/knowledge/glossary/KnowledgeGlossary.vue'),
            }
          : { redirect: '/knowledge/faq' }),
      },
    ],
  },
  {
    path: '/bulletin-board',
    name: 'bulletin-board',
    component: (): Promise<object> =>
      import(/* webpackChunkName: "knowledge" */ '@/components/trading-board/TradingBoard.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/bulletin-board/create-ask/:id',
    name: 'bulletin-board-create-ask',
    component: (): Promise<object> =>
      import(
        /* webpackChunkName: "knowledge" */ '@/components/trading-board/trading-board-create-ask/TradingBoardCreateAsk.vue'
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/bulletin-board/create-bid/:id',
    name: 'bulletin-board-create-bid',
    component: (): Promise<object> =>
      import(
        /* webpackChunkName: "knowledge" */ '@/components/trading-board/trading-board-create-bid/TradingBoardCreateBid.vue'
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/bulletin-board/sell-negotiate/:id/:bidId',
    name: 'bulletin-board-sell-negotiate',
    component: (): Promise<object> =>
      import(
        /* webpackChunkName: "knowledge" */ '@/components/trading-board/trading-board-negotiate/TradingBoardNegotiate.vue'
      ),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/bulletin-board/buy-negotiate/:id/:bidId',
    name: 'bulletin-board-buy-negotiate',
    component: (): Promise<object> =>
      import(
        /* webpackChunkName: "knowledge" */ '@/components/trading-board/trading-board-negotiate/TradingBoardNegotiate.vue'
      ),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/bulletin-board/buy/:id/:bidId',
    name: 'bulletin-board-buy',
    component: (): Promise<object> =>
      import(/* webpackChunkName: "knowledge" */ '@/components/trading-board/trading-board-buy/TradingBoardBuy.vue'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/bulletin-board/sell/:id/:bidId',
    name: 'bulletin-board-sell',
    component: (): Promise<object> =>
      import(/* webpackChunkName: "knowledge" */ '@/components/trading-board/trading-board-sell/TradingBoardSell.vue'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/transaction/:id',
    component: (): Promise<object> =>
      import(/* webpackChunkName: "auth" */ '@/components/common/payment-provider-status/TransactionStatus.vue'),
    meta: {
      requiresLogout: false,
      requiresAuth: false,
    },
  },
];

const errorRedirectRoute: RouteConfig = {
  path: '/*',
  beforeEnter: (to, from, next): void => {
    const navigationLang = to.params.lang;
    if (navigationLang) {
      return next(`/${navigationLang}/error/404`);
    }
    return next('/error/404');
  },
};

// Expanding all the routes from the modules into one array
const moduleRoutesAsArray = Object.entries(moduleRoutes).reduce(
  // @ts-expect-error - ToDO: Fix this
  (previousArray, nextRouteKeyPair): RouteConfig[] => previousArray.concat(nextRouteKeyPair[1]),
  [] as RouteConfig[],
);

// Filtering all the routes that are already as module routes (by path)
const filteredMainRoutes = mainRoutes.filter(
  (route): boolean => !moduleRoutesAsArray.some((mRoute): boolean => mRoute.path === route.path),
);

// First the main routes with replicated ones with moduleRoutes removed
// Then the routes from the modules
// Then the generic error redirect
const finalRoutes = [...filteredMainRoutes, ...moduleRoutesAsArray, errorRedirectRoute];

const router = new Router({
  // Mixing all routes into one final array
  routes: withPrefix('/:lang?', finalRoutes),
  scrollBehavior(): { x: number; y: number } {
    return { x: 0, y: 0 };
  },
  // History is cleaner and a must for prerendering
  mode: 'history',
});

// Checking all the meta requirements for the going (next) page
const checkMeta = (to: Route, requires: string[]): Record<string, boolean> => {
  const metaResult = {};

  requires.forEach((req): void => {
    metaResult[req] = to.matched.some((record): boolean => record.meta[req]);
  });

  return metaResult;
};

/**
 * Replace the first substring between two slashes: /substring/
 * @param str string
 * @param newSubstring string
 * @returns string
 */
const replaceFirstSubstring = (str: string, newSubstring: string): string | false => {
  const firstSlash = str.indexOf('/');
  const secondSlash = str.indexOf('/', firstSlash + 1);
  const substringToReplace = str.slice(firstSlash + 1, secondSlash);

  if (substringToReplace === newSubstring) {
    return false;
  }

  return `${str.slice(0, firstSlash + 1)}${newSubstring}${str.slice(secondSlash)}`;
};

router.beforeEach(async (to, from, next): Promise<void> => {
  const fromPathWithoutLang = from.path.substring(3);
  const toPath = to.path;
  // Prevents navigation guard error for unneded rerouting from lang/url to url
  if (fromPathWithoutLang === toPath || from.path === to.path) {
    return;
  }
  const { currentUser } = auth;

  const meta = checkMeta(to, ['requiresAuth', 'requiresIdin', 'requiresLogout']);

  const pagesToBeChecked = ['/all-campaigns'];
  const redirectToWhenLoggingIn = (path: string): boolean =>
    pagesToBeChecked.some((page): boolean => path.startsWith(page));

  // We need to communicate to the mobile app page changes
  // so that we have control in the app of where we are.

  const message: WebViewMessageSelectedVueRouter = {
    action: 'innerNavigation',
    payload: {
      from: {
        fullPath: from.fullPath,
        name: from.name,
        params: from.params,
      },
      to: {
        fullPath: to.fullPath,
        name: to.name,
        params: to.params,
      },
    },
  };
  window.ReactNativeWebView?.postMessage(JSON.stringify(message));

  // Setting language according to where we go:
  // If user wants to change to specific lang (to)
  // If user remains in same lang (from)
  // User is in no lang mode (defaultLanguage)
  const { lang } = to.params;
  const { lang: previousLang } = from.params;
  const localStorageLang = localStorage.getItem(localStorageKey);
  const userDbSelectedLanguage = router.app.$store?.state?.user?.selectedLanguage as string | undefined;
  const toLang = lang || previousLang || localStorageLang || defaultLanguage;
  const fullToLang = toLang ? `/${toLang}` : 'nl';

  // If user logged in and has a valid language in db and is not the same the one were going to, change it
  if (isValidLang(userDbSelectedLanguage) && userDbSelectedLanguage !== toLang) {
    // Setting the language in firestore
    // eslint-disable-next-line no-useless-catch
    try {
      await router.app.$store?.dispatch('changeSelectedLanguage', { lang: toLang });
    } catch (error) {
      throw error;
    }
    // Redirect
    const newToPath = replaceFirstSubstring(from.path, toLang);
    if (newToPath) {
      next(newToPath);
      return;
    }
  }

  // If none of the languages are valid, redirect to default language
  if (!isValidLang(userDbSelectedLanguage) && !isValidLang(toLang)) {
    const newToPath = replaceFirstSubstring(from.path, defaultLanguage);
    if (newToPath) {
      next(newToPath);
      return;
    }
  }

  // Actual loading of the language
  // eslint-disable-next-line no-useless-catch
  try {
    const selectedLanguage = isValidLang(userDbSelectedLanguage) ? userDbSelectedLanguage : toLang;
    const admin = await collections.admin;
    // @ts-expect-error - ToDo: fix types
    if (admin?.assetTypes) {
      // @ts-expect-error - ToDo: fix types
      await loadLanguageAsync(selectedLanguage, { assetTypes: admin.assetTypes });
    } else {
      await loadLanguageAsync(selectedLanguage);
    }
  } catch (error) {
    throw error;
  }
  // Mobile checks
  if (to?.query?.token && !currentUser) {
    await router.app.$store.dispatch('LoginwithCustomToken', { clientIdToken: to.query.token as string });
    next();
    return;
  }
  switch (true) {
    case to.name &&
      !from.path.includes('/login') &&
      !from.path.includes('/register') &&
      !from.path.includes('/404') &&
      redirectToLoginIfNoUser &&
      !currentUser:
      next({ path: '/login' });
      break;
    case meta.requiresLogout && currentUser?.emailVerified:
      next({ path: '/all-campaigns' });
      break;
    // Pages the user needs to be logged in && email activated to see them
    case meta.requiresAuth && !currentUser?.emailVerified && !to.query?.token:
      if (from.fullPath === '/') {
        // no previous page we can directly redirect to the login
        next({ path: '/login' });
      } else {
        // otherwise we open the modal
        void router.app.$store.dispatch('openModal', {
          type: enabled2FA ? AuthComponents.Login2FA : AuthComponents.Login,
          routeTo: to.fullPath,
        });
        next(false);
      }
      break;
    // Pages that need to be redirected to after logging in
    case to.path.startsWith('/login') && redirectToWhenLoggingIn(from.path) && Object.keys(to.query).length === 0:
      if (from.fullPath === '/') {
        next({ path: '/login' });
      } else {
        void router.app.$store.dispatch('openModal', {
          type: enabled2FA ? AuthComponents.Login2FA : AuthComponents.Login,
          routeTo: to.fullPath,
        });
        next(false);
      }
      break;
    default: {
      const redirectTo = lang ? to.fullPath : `${fullToLang}${to.fullPath}`;
      if (to.fullPath !== redirectTo) {
        next({ path: redirectTo });
        return;
      }
      next();
    }
  }
});

// @ts-expect-error - Wrong types
window.router = {
  replace: (props): Promise<Route> => router.replace(props),
};

export default router;
