import { Module } from 'vuex';
import { State } from '@/store/models';
import moment from 'moment';
import BigNumber from 'bignumber.js';
import { InvestmentEarning } from '../models/earning';
import { Investment } from '../models/investment';
import { Asset } from '../models/asset';

export default {
  state: [],
  mutations: {},
  actions: {},
  getters: {
    getEarningsByInvestmentId:
      (state): ((investmentId: string) => InvestmentEarning[]) =>
      (investmentId: string): InvestmentEarning[] =>
        state.filter((investmentEarning): boolean => investmentEarning.investment.id === investmentId),
    getTotalYearEarningsByAssetType:
      (state): ((assetType: string) => number) =>
      (assetType: string): number =>
        state
          .reduce((acum, investmentEarning): BigNumber => {
            if (
              ((investmentEarning.investment as Investment)?.asset as Asset)?.fundType === assetType &&
              investmentEarning.earningDateTime.toMillis() >= moment().subtract(1, 'y').valueOf()
            ) {
              acum = acum.plus(investmentEarning.amount);
            }
            return acum;
          }, new BigNumber(0))
          .toNumber(),
    getTotalQuarterEarningsByAssetType:
      (state): ((assetType: string) => number) =>
      (assetType: string): number =>
        state
          .reduce((acum, investmentEarning): BigNumber => {
            if (
              ((investmentEarning.investment as Investment)?.asset as Asset)?.fundType === assetType &&
              investmentEarning.earningDateTime.toMillis() >= moment().subtract(3, 'M').valueOf()
            ) {
              acum = acum.plus(investmentEarning.amount);
            }
            return acum;
          }, new BigNumber(0))
          .toNumber(),
    getTotalMonthEarningsByAssetType:
      (state): ((assetType: string) => number) =>
      (assetType: string): number =>
        state
          .reduce((acum, investmentEarning): BigNumber => {
            if (
              ((investmentEarning.investment as Investment)?.asset as Asset)?.fundType === assetType &&
              investmentEarning.earningDateTime.toMillis() >= moment().subtract(1, 'M').valueOf()
            ) {
              acum = acum.plus(investmentEarning.amount);
            }
            return acum;
          }, new BigNumber(0))
          .toNumber(),
  },
} as Module<InvestmentEarning[], State>;
