import Vue from 'vue';
import to from 'await-to-js';
import { Module, ActionContext } from 'vuex';
import { StateSlice, InitialStateSlice, State } from '@/store/models';
import { functions } from '@/firebase';
import { CreateBidInput, ReserveBidInput, BidProvider, BidIds, NegociateBidInput, BidType } from '@/store/models/bid';

// Create sell bid
interface SellingBidInput extends CreateBidInput {
  action: 'sell';
}

// Create buy bid
interface BuyingBidInput extends CreateBidInput {
  action: 'buy';
  provider: BidProvider;
}

// Create a buy bid and relate it with a selling bid
interface ReserveOperBidInput extends ReserveBidInput {
  action: 'reserve';
}

const RESERVING_BID_ERROR = 'RESERVING_BID_ERROR';
const RESERVING_BID_SUCCESS = 'RESERVING_BID_SUCCESS';
const RESERVING_BID_PROCESSING = 'RESERVING_BID_PROCESSING';
const CREATING_BID_PROCESSING = 'CREATING_BID_PROCESSING';
const CREATING_BID_ERROR = 'CREATING_BID_ERROR';
const CREATING_BID_SUCCESS = 'CREATING_BID_SUCCESS';
const CANCELING_BID_PROCESSING = 'CANCELING_BID_PROCESSING';
const CANCELING_BID_ERROR = 'CANCELING_BID_ERROR';
const CANCELING_BID_SUCCESS = 'CANCELING_BID_SUCCESS';
const NEGOTIATION_ERROR = 'NEGOTIATION_ERROR';
const NEGOTIATION_SUCCESS = 'NEGOTIATION_SUCCESS';
const NEGOTIATION_PROCESSING = 'NEGOTIATION_PROCESSING';

export interface PaymentInitData {
  redirectUrl: string;
  lang: string;
  brand: string;
  type?: string;
}

export default {
  state: new InitialStateSlice(),
  mutations: {
    [CREATING_BID_ERROR](state: StateSlice, error: Error): void {
      Vue.set(state, 'status', 'error');
      Vue.set(state, 'error', error.message || 'Something went wrong');
      Vue.set(state, 'name', CREATING_BID_ERROR);
    },
    [CREATING_BID_PROCESSING](state: StateSlice): void {
      Vue.set(state, 'status', 'processing');
      Vue.set(state, 'name', CREATING_BID_PROCESSING);
    },
    [CREATING_BID_SUCCESS](state: StateSlice, payload: unknown): void {
      Vue.set(state, 'status', 'success');
      Vue.set(state, 'payload', payload);
      Vue.set(state, 'name', CREATING_BID_SUCCESS);
    },
    [RESERVING_BID_ERROR](state: StateSlice, error: Error): void {
      Vue.set(state, 'status', 'error');
      Vue.set(state, 'error', error.message || 'Something went wrong');
      Vue.set(state, 'name', RESERVING_BID_ERROR);
    },
    [RESERVING_BID_SUCCESS](state: StateSlice, payload: unknown): void {
      Vue.set(state, 'status', 'success');
      Vue.set(state, 'payload', payload);
      Vue.set(state, 'name', RESERVING_BID_SUCCESS);
    },
    [RESERVING_BID_PROCESSING](state: StateSlice): void {
      Vue.set(state, 'status', 'processing');
      Vue.set(state, 'name', RESERVING_BID_PROCESSING);
    },
    [CANCELING_BID_ERROR](state: StateSlice, error: Error): void {
      Vue.set(state, 'status', 'error');
      Vue.set(state, 'error', error.message || 'Something went wrong');
      Vue.set(state, 'name', CANCELING_BID_ERROR);
    },
    [CANCELING_BID_PROCESSING](state: StateSlice): void {
      Vue.set(state, 'status', 'processing');
      Vue.set(state, 'name', CANCELING_BID_PROCESSING);
    },
    [CANCELING_BID_SUCCESS](state: StateSlice, payload: unknown): void {
      Vue.set(state, 'status', 'success');
      Vue.set(state, 'payload', payload);
      Vue.set(state, 'name', CANCELING_BID_SUCCESS);
    },
    [NEGOTIATION_ERROR](state: StateSlice, error: Error): void {
      Vue.set(state, 'status', 'error');
      Vue.set(state, 'error', error.message || 'Something went wrong');
      Vue.set(state, 'name', NEGOTIATION_ERROR);
    },
    [NEGOTIATION_SUCCESS](state: StateSlice, payload: unknown): void {
      Vue.set(state, 'status', 'success');
      Vue.set(state, 'payload', payload);
      Vue.set(state, 'name', NEGOTIATION_SUCCESS);
    },
    [NEGOTIATION_PROCESSING](state: StateSlice): void {
      Vue.set(state, 'status', 'processing');
      Vue.set(state, 'name', NEGOTIATION_PROCESSING);
    },
  },
  actions: {
    async createBid({ commit }: ActionContext<StateSlice, State>, data: CreateBidInput): Promise<void> {
      commit(CREATING_BID_PROCESSING);

      let dataToSend: BuyingBidInput | SellingBidInput;

      if (data.type === BidType.Buy) {
        dataToSend = {
          ...data,
          action: 'buy',
        };
      } else {
        dataToSend = {
          ...data,
          action: 'sell',
        };
      }

      const [createBidError, createBidSuccess] = await to(functions.httpsCallable('bidOperation')(dataToSend));
      if (createBidError) {
        commit(CREATING_BID_ERROR, createBidError);
        return;
      }
      commit(CREATING_BID_SUCCESS, createBidSuccess);
    },
    async reserveBid({ commit }: ActionContext<StateSlice, State>, data: ReserveBidInput): Promise<void> {
      commit(RESERVING_BID_PROCESSING);
      const dataToSend: ReserveOperBidInput = {
        ...data,
        action: 'reserve',
      };

      const [sellBidError, sellBidSuccess] = await to(functions.httpsCallable('bidOperation')(dataToSend));
      if (sellBidError) {
        commit(RESERVING_BID_ERROR, sellBidError);
        return;
      }
      commit(RESERVING_BID_SUCCESS, sellBidSuccess);
    },
    async cancelBid({ commit }: ActionContext<StateSlice, State>, data: BidIds): Promise<void> {
      commit(CANCELING_BID_PROCESSING);
      const [cancelBidError, cancelBidSuccess] = await to(
        functions.httpsCallable('bidOperation')({
          ...data,
          action: 'cancel',
        }),
      );
      if (cancelBidError) {
        commit(CANCELING_BID_ERROR, cancelBidError);
        return;
      }
      commit(CANCELING_BID_SUCCESS, cancelBidSuccess);
    },
    async negotiateBid({ commit }: ActionContext<StateSlice, State>, data: NegociateBidInput): Promise<void> {
      commit(NEGOTIATION_PROCESSING);
      const [negotiationError, negotiationSuccess] = await to(
        functions.httpsCallable('bidOperation')({
          ...data,
          action: 'negotiate',
        }),
      );
      if (negotiationError) {
        commit(NEGOTIATION_ERROR, negotiationError);
        return;
      }
      commit(NEGOTIATION_SUCCESS, negotiationSuccess);
    },
  },
} as Module<StateSlice, State>;
