export enum GenericPaymentMethods {
  Wallet = 'wallet',
  Ideal = 'ideal',
  BanContact = 'bancontact',
  Sofort = 'sofort',
  Sepa = 'sepa',
  PaypalPc = 'paypal-ppcp',
  CreditCard = 'creditcard',
  MyBank = 'mybank',
  Giropay = 'giropay',
  Mandate = 'mandate',
  Multi = 'multi',
  'pi-single' = 'pi-single',
}

export interface PaymentOptions {
  id: number;
  image: string;
  value: GenericPaymentMethods;
}

export enum CheckoutStepNames {
  Questionnaire = 'Questionnaire', // Questionnaire
  Identification = 'Identification', // normal identification component
  Investment = 'Investment', // CheckoutInvestment
  Terms = 'Terms', // CheckoutLegal
  PaymentMethods = 'PaymentMethods', // CheckoutPaymentMethods
  Payment = 'Payment', // no component
  Status = 'Status', // CheckoutStatus
  RiskQuestionnaire = 'RiskQuestionnaire',
  Signature = 'Signature',
}

export const getEnumUrlValue = (enumValue): string => {
  const enumMap = {
    Questionnaire: 'questionnaire',
    Identification: 'identification',
    Investment: 'investment',
    Terms: 'legal',
    PaymentMethods: 'payment-methods',
    Payment: 'payment',
    Status: 'status',
    RiskQuestionnaire: 'riskquestionnaire',
    Signature: 'signature',
  };

  return enumMap[enumValue] || '';
};

export enum IdentificationCheckoutStepNames {
  Identification = 'identification', // normal identification component
  Questionnaire = 'questionnaire', // Questionnaire
  RiskQuestionnaire = 'riskQuestionnaire',
}

export const getEnumStringValue = (enumValue): string => {
  const enumMap = {
    [CheckoutStepNames.Questionnaire]: 'Questionnaire',
    [CheckoutStepNames.Identification]: 'Identification',
    [CheckoutStepNames.Investment]: 'Investment',
    [CheckoutStepNames.Terms]: 'Terms',
    [CheckoutStepNames.PaymentMethods]: 'PaymentMethods',
    [CheckoutStepNames.Payment]: 'Payment',
    [CheckoutStepNames.Status]: 'Status',
    [CheckoutStepNames.RiskQuestionnaire]: 'RiskQuestionnaire',
    [CheckoutStepNames.Signature]: 'Signature',
  };

  return enumMap[enumValue] || '';
};

export interface Checkout {
  assetId: string;
  assetHideDividendsFormat: boolean | undefined;
  sharesAmount: number;
  euroAmount: number;
  totalEur: number;
  totalEmissionCosts: number;
  selectedDividendsFormatYear: [string, number];
  nextStep?: CheckoutStepNames | IdentificationCheckoutStepNames | 'lastStep';
  questionaireQuestionAndAnswers?: { question: string; answer: string; type: string }[];
  knowledgeQuestionAndAnswers?: { question: string; answer: string; type: string }[];
  knowledgeQuestionAndAnswersUnprocessed?: { question: string; answer: string; type: string }[];
  experienceQuestionAndAnswers?: { question: string; answer: string; type: string }[];
  experienceQuestionAndAnswersUnprocessed?: { question: string; answer: string; type: string }[];
  questionaireVersion?: string;
  signatureChoice?: string;
  active?: string | null;
  freelyInvestedAssets?: number;
  simulationCalcuationResult?: number;
  netAnnualIncome?: number;
  liquidAssetsValue?: number;
  financialCommitmentsValue?: number;
  incomeType?: null | boolean;
  agressWithRisk?: null | boolean;
  agressWithRiskWarning?: null | boolean;
  legalPageSigned?: null | boolean;
  fractionalEnabled?: boolean;
}

export interface CheckoutCloudFunctionParameters {
  assetId: string;
  redirectUrl: string;
  sharesAmount: number;
  lang: string;
  selectedDividendsFormatYear: [string, number];
  brand?: string;
  type: undefined | 'loan'; // this is not a parameter in the CF but we still send it for now
  legalDocs?: unknown[];
  questionaireQuestionAndAnswers?: { question: string; answer: string; type: string }[];
  knowledgeQuestionAndAnswers?: { question: string; answer: string; type: string }[];
  signatureChoice?: string;
}

export interface Step {
  name: CheckoutStepNames | IdentificationCheckoutStepNames;
  /**
   * what is `matched` in the router
   */
  route: string;
  /**
   * the pointer to the correct v18n property
   */
  display: string;
}
