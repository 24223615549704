/**
 * Client configs.
 */

module.exports = {
  multiclient: {
    enabled: false,
  },
  mobilePhoneApp: false,
  currency: '€',
  logo: 'zib/logo.svg',
  logoAlt: 'zib/logo-white.svg',
  logoShare: 'bloqhouse.png',
  logoPremium: 'zib-premium.svg',
  location: 'EUROPE-WEST1',
  internalClientName: 'zib',
  iconName1: 'favicon.ico',
  iconName2: 'favicon.svg',
  iconName3: 'favicon-512.png',
  scssVariables: './src/scss/variables/brands/_zib.scss',
  name: 'ZIB',
  phone: '+31 010 283 06 87',
  email: 'info@zibinvestments.nl',
  website: 'https://www.zibinvestments.nl/',
  glossary: true,
  langFileName: 'zib/language-config.ts',
  paymentServiceProvider: 'Custom',
  oppSource: '',
  bloqhouseBrandingEnabled: true,
  pescheckEnabled: true,
  modules: {
    landing: false,
    identification: {
      default: true, // setting this to false requires manual changes,
      itsme: false,
    },
  },
  landingRedirect: '/all-campaigns',
  loginRedirect: '/account',
  intercom: false,
  requireContractAgreement: true,
  requireQuestionnaire: false,
  functionality: {
    enableMeerdervoortApi: false,
    idinGlobalSwitch: false,
    containerGlobalSwitch: true,
    progressEndpoint: false,
    loanEmail: false, // THIS IS MEERDERVOORT HARDCODED. KEEP DISABLED FOR ALL CLIENTS THAT ARE NOT MEERDERVOORT
    questionnaire: {
      folderName: 'zib',
    },
    checkout: {
      initCheckoutStore: 'zib',
      stylingFolderName: 'zib',
      remoteCheckoutEnabled: true,
      steps: ['Identification', 'Investment', 'AmlForm', 'Questionnaire', 'RiskQuestionnaire', 'Signature'],
      resetCheckoutExceptWhenAt: ['AmlForm', 'Questionnaire', 'RiskQuestionnaire', 'Signature'],
      enableCheckoutQuestionnaire: true,
      checkoutInvestment: {
        folderName: 'zib',
        firstParagraphEnabled: false,
        showMinimumAmount: true,
      },
      checkoutLegal: {
        enableLegalDoc: false,
        footerButtonText: 'checkout.investment.nextStep',
        footerButtonNoteText: 'checkout.legal.continue',
      },
      checkoutFooter: {
        rowJustifyContentEnabled: true,
        checkoutUpdateType: 'zib',
      },
      checkoutQuestionnaire: {
        folderName: 'zib',
      },
      checkoutRiskQuestionnaire: {
        folderName: 'default',
      },
      checkoutRecap: {
        finalisingPaymentTextEnabled: false,
      },
      checkoutStatus: {
        message:
          'Uw inschrijving is door ons in goede orde ontvangen. ' +
          'Ter bevestiging ontvangt u van ons een e-mail. Wij zullen het klantenonderzoek uitvoeren, waarna u weer een bericht van ons ontvangt.',
        statusEnabled: true,
        titleEnabled: false,
        navigateToProjectButtonEnabled: false,
        showButtons: true,
      },
    },
    paymentInitTransaction: {
      loanTypeFundMax: 80000,
      containsLegalTerms: true,
      paymentInit: {
        fundTypeEnabled: true,
        signatureChoiceEnabled: true,
        legalDocsEnabled: true,
        questionaireQuestionAndAnswersEnabled: true,
        knowledgeQuestionAndAnswersEnabled: true,
      },
    },
    generateDividendsEarnings: 'generateDividendsPDFZIB',
    headerForPDF: false,
    customSignUpGreeting: 'ZIB Investments',
    index: {
      preloadHref:
        '"https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;1,400;1,600&display=swap"',
      stylesheetHref:
        '"https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;1,400;1,600&display=swap"',
    },
    dividendsFormat: true,
    dividendsReturnsOptions: {
      checkIfAssetHasFixedDividends: false,
    },
    bannerLegal: {
      type: 'image',
      requireLegalBanner: false,
      legalBannerNoRequiresLicense: false,
      legalBannerPosition: 'top',
      phoneNumber: false,
    },
    navbar: {
      boxedEnabled: true,
      languageChoice: {
        enableLanguageChoice: false,
        languages: ['nl'],
        defaultLanguage: 'nl',
      },
      externalLink: 'dynamic',
      backgroundPrimary: false,
      containerEnabled: true,
      shadowEnabled: false,
      authPageRedirect: 'account',
      customNonLocalNonAuthLogin: true,
      logoSize: 'extraSmall',
      showDropDownMenu: true,
      navbarLinks: {
        allCampaigns: {
          visible: true,
        },
        knowledge: {
          visible: false,
          url: '',
        },
        home: {
          visible: true,
        },
      },
      navIcon: {
        homeIcon: true,
        projectsIcon: true,
      },
    },
    accountSettings: {
      salutation: true,
      initials: true,
      middleName: true,
      country: true,
    },
    accountDashboard: {
      view: 'tabulated',
      holdingsImagePath: '@/assets/illustrations/dashboard/holdings/zib/holdings-res.svg',
      pendingPaymentsInfoboxEnabled: true,
    },
    accountBalance: {
      customImage: false,
      customWrapperClass: 'account__balance',
      animationsEnabled: false,
    },
    accountInvestments: {
      transactionAmountWrapperClassName: 'transactions-item-total',
      boughtSharesExcludeLoan: true,
      checkForPremium: false,
    },
    accountSingleFund: {
      availableProjectsTotal: 'total',
      textCheckFundType: false,
      displayInterest: true,
      overlayClassEnabled: true,
      firstWithDownloadVersion: 'paymentsOptionalAsset',
      totalsSectionEnabled: false,
      totalsSectionTwoEnabled: true,
      totalsSource: 'asset',
      dividendsSource: 'totalEarnings',
      includeIcon: true,
      completedBoxTextClassEnabled: false,
      netValueCheckIfNoInvestments: false,
      premiumClassEnabled: false,
      paymentDateEarningsHistory: true,
    },
    property: {
      secondaryDocUnitedStates: false,
      secondaryDocThreshold: false,
      availableNumbersEnabled: false,
      showContactAddress: false,
      showInterestEnabled: true,
      transformedDateTimeEnabled: true,
      openCarousel: true,
      showDocsInReservation: false,
    },
    propertiesGridFund: {
      disableDocsIfNotInvested: false,
      contentsCardEnabled: true,
      subtitle: 'tooltipAndTitle',
    },
    properties: {
      toolbarSearchEnabled: true,
      dateSortingProperty: 'startDateTime',
      titleEnabled: false,
    },
    identificationRequest: {
      imageFolderWorld: 'default/zibtest/world.svg',
      imageFolderBusiness: 'default/zibtest/company.svg',
      imageFolderWorldIcon: 'default/world-ico.svg',
      imageFolderBusinessIcon: 'default/company-ico.svg',
      steps: ['identification'],
      dateOfBirthFormat: 'DD-MM-YYYY',
      uploadPassport: true,
      businessRiskClassProp: true,
      businessExperienceProp: true,
      worldRiskClassProp: true,
      worldExperienceProp: true,
      businessInputs: {
        gender: {
          inputName: 'gender',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          inputType: 'formSelect',
          options: 'genderOptions',
          label: 'common.gender',
        },
        initials: {
          inputName: 'initials',
          rules: 'required',
          class: 'col--6',
          autocomplete: true,
          type: '',
          label: 'common.initials',
          inputType: 'formInput',
        },
        firstName: {
          inputName: 'firstName',
          rules: 'required|alpha_spaces|max:40',
          class: 'col--12',
          autocomplete: true,
          type: '',
          label: 'common.firstName',
          inputType: 'formInput',
        },
        middleName: {
          inputName: 'middleName',
          rules: 'required|max:40',
          class: 'col--6',
          autocomplete: true,
          type: '',
          label: 'common.middleName',
          inputType: 'formInput',
        },
        surname: {
          inputName: 'surname',
          rules: 'required|alpha_spaces|max:40',
          class: 'col--6',
          autocomplete: true,
          type: '',
          label: 'common.surname',
          inputType: 'formInput',
        },
        telephone: {
          inputName: 'telephone',
          rules: 'required',
          class: 'col--6',
          autocomplete: true,
          type: 'tel',
          label: 'common.telephone',
          inputType: 'formInput',
        },
        dateOfBirth: {
          inputName: 'dateOfBirth',
          rules: 'required',
          class: 'col--6',
          autocomplete: true,
          type: '',
          inputType: 'formDatePicker',
          label: 'common.dateOfBirth',
        },
        bankAccount: {
          inputName: 'bankAccount',
          rules: 'required|alpha_num|max:40|min:18',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'common.iban',
          inputType: 'formInput',
        },
        bankAccountOwner: {
          inputName: 'bankAccountOwner',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'common.bankAccountOwner',
          inputType: 'formInput',
        },
        nationality: {
          inputName: 'nationality',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          inputType: 'formSelect',
          options: 'countries',
          label: 'common.nationality',
        },
        address: {
          inputName: 'address',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'common.address',
          inputType: 'formInput',
        },
        houseNumber: {
          inputName: 'houseNumber',
          rules: 'required',
          class: 'col--4',
          autocomplete: false,
          type: '',
          label: 'common.houseNumber',
          inputType: 'formInput',
        },
        city: {
          inputName: 'city',
          rules: 'required',
          class: 'col--4',
          autocomplete: false,
          type: '',
          label: 'common.city',
          inputType: 'formInput',
        },
        postalCode: {
          inputName: 'postalCode',
          rules: 'required',
          class: 'col--4',
          autocomplete: false,
          type: '',
          label: 'common.postalCode',
          inputType: 'formInput',
        },
        country: {
          inputName: 'country',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          inputType: 'formSelect',
          options: 'countries',
          label: 'common.country',
        },
        companyName: {
          inputName: 'companyName',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'common.companyName',
          inputType: 'formInput',
        },
        kvkNumber: {
          inputName: 'kvkNumber',
          rules: 'required|integer',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'identification.business.kvkNumber',
          inputType: 'formInput',
        },
        kvkImage: {
          inputName: 'kvkImage',
          rules: '',
          class: 'col--12 upload-passport',
          autocomplete: false,
          type: '',
          inputType: 'formFileInput',
          label: 'identification.business.uploadYourKvKImage',
        },
        passport: {
          inputName: 'passport',
          rules: '',
          class: 'col--12 upload-passport',
          autocomplete: false,
          type: '',
          inputType: 'formFileInput',
          label: 'identification.world.uploadYourPassport',
        },
        passportExpirationDate: {
          inputName: 'passportExpirationDate',
          rules: '',
          class: 'col--12',
          autocomplete: true,
          type: '',
          inputType: 'formDatePicker',
          label: 'common.passportExpirationDate',
        },
        receivePhysicalMailings: {
          inputName: 'receivePhysicalMailings',
          rules: 'required',
          class: 'checkbox',
          autocomplete: false,
          type: '',
          inputType: 'checkbox',
          label: 'common.receivePhysicalMailings',
        },
      },
      worldInputs: {
        gender: {
          inputName: 'gender',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          inputType: 'formSelect',
          options: 'genderOptions',
          label: 'common.gender',
        },
        initials: {
          inputName: 'initials',
          rules: 'required',
          class: 'col--6',
          autocomplete: true,
          type: '',
          label: 'common.initials',
          inputType: 'formInput',
        },
        firstName: {
          inputName: 'firstName',
          rules: 'required|alpha_spaces|max:40',
          class: 'col--12',
          autocomplete: true,
          type: '',
          label: 'common.firstName',
          inputType: 'formInput',
        },
        middleName: {
          inputName: 'middleName',
          rules: 'required|max:40',
          class: 'col--6',
          autocomplete: true,
          type: '',
          label: 'common.middleName',
          inputType: 'formInput',
        },
        surname: {
          inputName: 'surname',
          rules: 'required|alpha_spaces|max:40',
          class: 'col--6',
          autocomplete: true,
          type: '',
          label: 'common.surname',
          inputType: 'formInput',
        },
        telephone: {
          inputName: 'telephone',
          rules: 'required',
          class: 'col--6',
          autocomplete: true,
          type: 'tel',
          label: 'common.telephone',
          inputType: 'formInput',
        },
        dateOfBirth: {
          inputName: 'dateOfBirth',
          rules: 'required',
          class: 'col--6',
          autocomplete: true,
          type: '',
          inputType: 'formDatePicker',
          label: 'common.dateOfBirth',
        },
        bankAccount: {
          inputName: 'bankAccount',
          rules: 'required|alpha_num|max:40|min:18',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'common.iban',
          inputType: 'formInput',
        },
        bankAccountOwner: {
          inputName: 'bankAccountOwner',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'common.bankAccountOwner',
          inputType: 'formInput',
        },
        nationality: {
          inputName: 'nationality',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          inputType: 'formSelect',
          options: 'countries',
          label: 'common.nationality',
        },
        address: {
          inputName: 'address',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'common.address',
          inputType: 'formInput',
        },
        houseNumber: {
          inputName: 'houseNumber',
          rules: 'required',
          class: 'col--4',
          autocomplete: false,
          type: '',
          label: 'common.houseNumber',
          inputType: 'formInput',
        },
        city: {
          inputName: 'city',
          rules: 'required',
          class: 'col--4',
          autocomplete: false,
          type: '',
          label: 'common.city',
          inputType: 'formInput',
        },
        postalCode: {
          inputName: 'postalCode',
          rules: 'required',
          class: 'col--4',
          autocomplete: false,
          type: '',
          label: 'common.postalCode',
          inputType: 'formInput',
        },
        country: {
          inputName: 'country',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          inputType: 'formSelect',
          options: 'countries',
          label: 'common.country',
        },
        passport: {
          inputName: 'passport',
          rules: '',
          class: 'col--12 upload-passport',
          autocomplete: false,
          type: '',
          inputType: 'formFileInput',
          label: 'identification.world.uploadYourPassport',
        },
        passportExpirationDate: {
          inputName: 'passportExpirationDate',
          rules: '',
          class: 'col--12',
          autocomplete: true,
          type: '',
          inputType: 'formDatePicker',
          label: 'common.passportExpirationDate',
        },
        receivePhysicalMailings: {
          inputName: 'receivePhysicalMailings',
          rules: 'required',
          class: 'checkbox',
          autocomplete: false,
          type: '',
          inputType: 'checkbox',
          label: 'common.receivePhysicalMailings',
        },
      },
    },
    identificationStep: {
      questionnaireEnabled: false,
    },
    identificationForm: {
      redirectToAccount: false,
      buttonText: 'identification.FormStatus.nextStep',
    },
    settings: {
      itemPaddingAdded: true,
      details: 'full',
      detailsChange: true,
      identification: true,
      settingsAutoReditect: 'details',
    },
    register: {
      privacyStatementUrl: '',
      enableLanguageSelect: false,
      newsletter: true,
      privacyStatement: true,
    },
    login: {
      enabled2FA: false,
    },
    authReset: {
      includePleaseCheckInboxInToast: false,
    },
    dropdownMenu: {
      dropdownMenuType: 'initials',
      settingsNavigation: 'settings/details',
      dropdownMenuItems: {
        portfolio: {
          visible: true,
        },
        settings: {
          icon: 'cog',
          visible: true,
        },
      },
    },
    investor: {
      passportPropertyName: 'Paspoort',
    },
    footer: {
      type: 'standard',
      brandLogo: 'bloqhouse',
    },
  },
  emails: {
    from: 'ZIB Investments',
    userCreatedFromBloqadmin: {
      enabled: true,
      templateId: 'd-fee11237f3b74f059069e1b3e4782576',
    },
    sendSharesEmail: {
      format: 'default',
    },
    sharesBought: {
      format: 'salutationSurnameProjectName',
      templateId: 'd-8446d5f490b54709943ef308594a9cf9',
      enabled: true,
      signatureChoiceOnline: 'd-2b93072ce9a54e51993fdfcd6a633211',
      fromAdminTemplateId: 'd-2b93072ce9a54e51993fdfcd6a633211',
      sendlegalDocs: true,
    },
    sharesBoughtAdminNotification: {
      to: 'test@bloqhouse.com',
      templateId: 'd-5b7c646a15cf418bad079cf3e8a0d98a',
      enabled: true,
      signatureChoiceOnline: 'd-b0ff2c539d7c4097a3b56343269b4ba3',
    },
    verifyEmail: {
      enabled: true,
      templateId: 'd-0dcb62fec5054265b207f3635ea05a48',
      format: 'email',
    },
    passwordReset: {
      enabled: true,
      templateId: 'd-fee11237f3b74f059069e1b3e4782576',
      format: 'salutationSurname',
    },
    identificationRequest: {
      approved: {
        enabled: true,
        templateId: 'd-e958d0fdba9249b9a67f561d94ed516a',
      },
      rejected: {
        enabled: true,
        templateId: 'd-c71e022a7b244c9382d5a9290ff4bb9b',
      },
      format: 'salutationSurnameUrl',
    },
    createIdentificationRequest: {
      fromClientName: true,
      toClientName: true,
      toClientEmail: [],
    },
    paymentRequest: {
      enabled: true,
      templateId: 'd-9c0dcdde66214ec0ac2d12e5ee60fc2a',
    },
    translations: {
      sharesBoughtInstrument: {
        subject: 'Obligaties',
        content: 'obligatiehouder',
      },
    },
    contactSupportByEmail: {
      from: 'ZIB Investments <info@zibinvestments.nl>',
      to: 'hugo@bloqhouse.com',
      cc: true,
    },
  },
};
