import { Module } from 'vuex';
import { State } from '@/store/models';
import { Valuation } from '@/store/models/valuation';

export default {
  state: [],
  mutations: {},
  actions: {},
  getters: {
    getActiveValuationByAsset:
      (state): ((assetId: string) => unknown) =>
      (assetId: string): Valuation | undefined =>
        state.find((valuation): boolean => valuation.asset.id === assetId),
    getInitialValuationByAsset:
      (state): ((assetId: string) => unknown) =>
      (assetId: string): Valuation | undefined =>
        state.find((valuation): boolean => valuation.asset.id === assetId && valuation.id === 'initial'),
  },
} as Module<Valuation[], State>;
