import firebase from 'firebase/compat/app';
import { QuestionnaireAnswers } from '@/store/models/questionnaire';
import { Idin, FormattedIdin } from './idin';
import {
  BusinessIdentification,
  IdentificationRequest,
  PrivateIdentification,
  IdentificationRequestStatus,
} from './identificationRequest';
import { Pescheckv3PescheckDocument } from './pescheck';

/**
 * Defining the different roles for managers.
 */
export enum ManagerRole {
  Superadmin = 'superadmin',
  Admin = 'admin',
  Editor = 'editor',
}

// Workaround for the importing problem as JS object.
export const roles = Object.values(ManagerRole);

/**
 * This is the banhammer interface. Handles the complete disability
 * of the user to interact with the web application.
 */
export enum UserStatus {
  Disabled = 'disabled',
  Enabled = 'enabled',
}

/**
 * Representing our current KYC methods excluding Pescheck.
 */
export enum KYCMethods {
  Idin = 'idin',
  Private = 'private',
  Business = 'business',
  Itsme = 'itsme',
}

/**
 * Defining the user's tier and what's his/her account privileges/data.
 */
export enum UserTier {
  Account = 'account',
  Investor = 'investor',
  Trader = 'trader',
}

/* Opp data */

export const enum MerchantStatus {
  new = 'new',
  pending = 'pending',
  live = 'live',
  suspended = 'suspended',
  terminated = 'terminated',
  blocked = 'blocked',
}

export const enum ComplianceStatus {
  unverified = 'unverified',
  pending = 'pending',
  verified = 'verified',
}
export interface OppStatus {
  merchantStatus: MerchantStatus;
  complianceStatus: ComplianceStatus;
  complianceUrl: string;
  requirements: string[];
}

/**
 * Main User interface. At this form, the user is on the 'account' tier since it does not have advanced
 * to any other higher tier.
 */
export interface User {
  deleted: boolean;
  clientName?: string;
  gender?: string;
  bankAccount?: string;
  nationality?: string;
  createdDateTime: firebase.firestore.Timestamp;
  customId: number;
  email: string;
  initials?: string;
  middleName?: string;
  experience: string;
  id?: string;
  balance?: number; // Merchant balance
  balanceReserved?: number; // Reserved balance
  /**
   * The 'identificationRequest' indicates that there is an iR but not approved yet / rejected
   */
  identificationRequest?: firebase.firestore.DocumentReference | PrivateIdentification | BusinessIdentification;
  /**
   * The 'idin' property here indicates that the user could have a failed / not completed idin transaction.
   */
  idin?: firebase.firestore.DocumentReference | Idin;
  pescheck?: firebase.firestore.DocumentReference | Pescheckv3PescheckDocument;
  passport?: string;
  passportSecond?: string;
  kvkImage?: string;
  dateExcerpt?: string;
  status: UserStatus;
  statusMessage?: string;
  tier: UserTier;
  questionnaire?: QuestionnaireAnswers;
  usedBankAccounts?: { value: string; lastUsed: firebase.firestore.Timestamp }[];
  updatedDateTime: firebase.firestore.Timestamp;
  receivePhysicalMailings?: boolean;
  extraEmail?: string[];
  kycMethod?: KYCMethods;
  questionnaireAnswered?: boolean;
  questionnaireCorrectPercentage?: number;
  simulationAnswered?: boolean;
  idRequestStatus: IdentificationRequestStatus;
  netIncome?: number;
  capacity?: number;
  selectedLanguage?: string;
  walletId?: string;
  oppData?: OppStatus;
}

/**
 * Basic User plus idin data transformed into legible properties.
 */
export interface IdinInvestor extends Omit<User, 'identificationRequest' | 'gender'>, FormattedIdin {
  kycMethod: KYCMethods.Idin;
  tier: UserTier.Investor;
  idin: firebase.firestore.DocumentReference | Idin;
  identificationRequest?: firebase.firestore.DocumentReference | IdentificationRequest;
}

/**
 * Basic User plus itsme data transformed into legible properties.
 */
export interface ItsmeInvestor extends Omit<User, 'identificationRequest' | 'gender'>, FormattedIdin {
  kycMethod: KYCMethods.Itsme;
  tier: UserTier.Investor;
  identificationRequest?: firebase.firestore.DocumentReference | IdentificationRequest;
}

/**
 * Basic User plus the private identification data.
 */
export interface PrivateInvestor
  extends Omit<User, 'idin' | 'bankAccount' | 'initials' | 'gender'>,
    Omit<PrivateIdentification, 'status'> {
  kycMethod: KYCMethods.Private;
  tier: UserTier.Investor;
  identificationRequest?: firebase.firestore.DocumentReference | PrivateIdentification;
}

/**
 * Basic User plus the business identification data.
 */
export interface BusinessInvestor
  extends Omit<User, 'idin' | 'bankAccount' | 'initials' | 'kvkImage' | 'gender'>,
    Omit<BusinessIdentification, 'status'> {
  kycMethod: KYCMethods.Business;
  tier: UserTier.Investor;
  identificationRequest?: firebase.firestore.DocumentReference | BusinessIdentification;
}

/**
 * Discrimination union between all the investor types. The 'kycMethod' field makes the difference.
 * At this point the User is an Investor and has the `tier === UserTier.Investor`.
 */
export type Investor = IdinInvestor | ItsmeInvestor | PrivateInvestor | BusinessInvestor;

/**
 * Type guard to check if someone is an investor.
 * @param userOrInvestor basic User or Investor interfaces.
 */
export const isInvestor = (userOrInvestor: User | Investor): userOrInvestor is Investor =>
  userOrInvestor.tier === UserTier.Investor;

/**
 * Checks if the investor is Kyc'ed via Idin or Identification Request.
 */
export const isIdinInvestor = (userOrInvestor: Investor | User): boolean =>
  userOrInvestor.kycMethod === KYCMethods.Idin;

export const isPrivateInvestor = (userOrInvestor: Investor | User): boolean =>
  userOrInvestor.kycMethod === KYCMethods.Private;

export const isBusinessInvestor = (userOrInvestor: Investor | User): boolean =>
  userOrInvestor.kycMethod === KYCMethods.Business;

export const isIdentificationRequestInvestor = (userOrInvestor: Investor | User): boolean =>
  isPrivateInvestor(userOrInvestor) || isBusinessInvestor(userOrInvestor);

/**
 * Data for a bank account change request.
 */
export interface BankAccountChange {
  id?: string;
  bankAccount: string;
  changed: boolean;
  previousBankAccount: string;
  userId: string;
  createdDateTime: firebase.firestore.Timestamp;
}

/**
 * Data for a bank account change request.
 */
export interface DataChangeRequest {
  type: 'name' | 'bankAccount' | 'address' | 'email';
  status: 'pending' | 'approved' | 'rejected';
  previousData:
    | { name: string; surname: string }
    | { bankAccount: string }
    | { country: string; streetAddress: string; houseNumber: string; postalCode: string }
    | { email: string };

  newData:
    | { name: string; surname: string }
    | { bankAccount: string }
    | { country: string; streetAddress: string; houseNumber: string; postalCode: string }
    | { email: string };
}
