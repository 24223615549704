import { clientConfig } from '@/helpers/clientData';

/**
 * Helper function to create templates for title meta tags
 * as the templating function provided by Vue-meta is limited
 *
 * Once they provide a better way for templating we should use that
 *
 * @see https://github.com/nuxt/vue-meta/issues/141#issuecomment-532692578
 */
export const titleTemplate = (chunk: string): string => {
  const whitelabelConfig = clientConfig();
  return `${chunk} - ${whitelabelConfig.name}`;
};
