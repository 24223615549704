import Vue from 'vue';
import { ActionContext, Module } from 'vuex';
import { storage } from '@/firebase';
import { Downloads, State, StateSlice } from '@/store/models';

export const GET_DOWNLOADS_ERROR = 'GET_DOWNLOADS_ERROR';
export const GET_DOWNLOADS_SUCCESS = 'GET_DOWNLOADS_SUCCESS';
export const GET_DOWNLOADS_PROCESSING = 'GET_DOWNLOADS_PROCESSING';

export interface DownloadPath {
  collection: keyof State;
  id?: string;
  properties: string | string[];
}

export default {
  state: {},
  getters: {
    getDownloadObjectFromState:
      (state): ((downloadPath: DownloadPath) => unknown | undefined) =>
      ({ collection, id, properties: property }: DownloadPath): unknown | undefined => {
        // @ts-expect-error - ToDo: fix this error
        if ((collection === 'user' && !state.investors) || (collection !== 'user' && !state[collection])) {
          return undefined;
        }
        if (
          id &&
          // @ts-expect-error - ToDo: fix this error
          ((collection === 'user' && !state.investors[id]) || (collection !== 'user' && !state[collection][id]))
        ) {
          return undefined;
        }
        return id
          ? state[collection === 'user' ? 'investors' : collection][id][property as string]
          : state[collection][property as string];
      },
    getDownloadsByPath:
      (state, getters): ((downloadPath: DownloadPath) => [string, string][] | undefined) =>
      ({ collection, id, properties: property }: DownloadPath): [string, string][] | undefined => {
        const found = getters.getDownloadObjectFromState({ collection, id, properties: property });
        return found && found.status === 'success' ? found.payload : undefined;
      },
    isDownloadsProcessing:
      (state, getters): ((downloadPath: DownloadPath) => boolean) =>
      ({ collection, id, properties: property }: DownloadPath): boolean => {
        const found = getters.getDownloadObjectFromState({ collection, id, properties: property });
        return !!found && found.status === 'processing';
      },
    didDownloadsFail:
      (state, getters): ((downloadPath: DownloadPath) => boolean) =>
      ({ collection, id, properties: property }: DownloadPath): boolean => {
        const found = getters.getDownloadObjectFromState({ collection, id, properties: property });
        return !!found && found.status === 'error';
      },
  },
  mutations: {
    [GET_DOWNLOADS_ERROR](
      state: Downloads,
      { path: { collection, id, properties: property }, error }: { path: DownloadPath; error: Error },
    ): void {
      const stateSlice = id ? state[collection][id][property] : state[collection][property];
      stateSlice.status = 'error';
      stateSlice.error = error.message || 'Something went wrong';
    },
    [GET_DOWNLOADS_SUCCESS](
      state: Downloads,
      {
        path: { collection, id, properties: property },
        downloads,
      }: { path: DownloadPath; downloads: [string, string][] },
    ): void {
      const stateSlice = id ? state[collection][id][property] : state[collection][property];
      stateSlice.status = 'success';
      stateSlice.payload = downloads;
    },
    [GET_DOWNLOADS_PROCESSING](
      state: Downloads,
      { path: { collection, id, properties: property } }: { path: DownloadPath },
    ): void {
      const stateSlice: StateSlice = {
        status: 'processing',
        error: '',
        name: '',
        payload: null,
      };

      // Construct the state - root properties need to be reactive
      if (!state[collection]) {
        Vue.set(state, `${collection}`, {});
      }

      state[collection] = {
        ...state[collection],
        ...(!id && { [property as string]: stateSlice }),
        ...(id && {
          [id]: {
            ...state[collection][id],
            [property as string]: stateSlice,
          },
        }),
      };
    },
  },
  actions: {
    async getDownloads(
      { commit, rootState, getters }: ActionContext<Downloads, State>,
      paths: DownloadPath | DownloadPath[],
    ): Promise<void> {
      // Validate and serialize
      const downloadList: { path: DownloadPath; downloadNames: string[] }[] = [];
      const inputErrors: Error[] = [];
      const pathArray = !Array.isArray(paths) ? [paths] : paths;

      // Accessing all the nested properties. If number, then it's an index from an array, otherwise it's a property.
      const getNestedProperty = (obj: State[keyof State] | State['user'], path: string): number | undefined => {
        // eslint-disable-next-line no-useless-escape
        const segments = path.split(/[\.\[\]]/).filter(Boolean);
        return segments.reduce(
          (o, p): number => (o ? (isNaN(Number(p)) ? o[p] : o[parseInt(p, 10)]) : undefined),
          obj as number | undefined,
        );
      };

      pathArray.forEach(({ collection, id, properties }): void => {
        if (!rootState[collection]) {
          inputErrors.push(new Error(`InputError: The collection name '${collection}' was not found or it is null`));
          return;
        }
        let targetObj: State[keyof State] | State['user'];
        if (collection !== 'user') {
          targetObj = !id
            ? rootState[collection]
            : Array.isArray(rootState[collection])
              ? rootState[collection].find((obj): boolean => obj.id === id)
              : rootState[collection][id];
        } else {
          targetObj = rootState[collection];
        }
        if (collection !== 'user' && !targetObj) {
          inputErrors.push(new Error(`InputError: The id ${id} was not found in collection ${collection}`));
          return;
        }

        const propertyArray = Array.isArray(properties) ? properties : [properties];
        propertyArray.forEach((incomingProperty): void => {
          const property = getNestedProperty(targetObj, incomingProperty);
          // TODO: Check if property is downloadable

          if (!property) {
            inputErrors.push(
              new Error(
                `InputError: Property ${incomingProperty} is not found in collection ${collection} with id ${id}`,
              ),
            );
            return;
          }
          // 'id' is only inlcuded if it is not undefined
          const path = {
            collection: collection === 'user' ? 'investors' : collection,
            ...(id && { id }),
            properties: incomingProperty,
          };
          commit(GET_DOWNLOADS_PROCESSING, { path });

          // Check if already dowloaded
          const dlItem = getters.getDownloadsByPath(path);
          if (dlItem) {
            commit(GET_DOWNLOADS_SUCCESS, { path, downloads: dlItem });
            return;
          }
          // @ts-expect-error - ToDo: fix this error
          downloadList.push({ path, downloadNames: property });
        });
      });

      // Exit execution if even one of the inputs were wrong
      if (inputErrors.length) {
        // eslint-disable-next-line @typescript-eslint/only-throw-error
        throw inputErrors;
      }

      // Download
      // eslint-disable-next-line @typescript-eslint/await-thenable
      await downloadList.forEach(({ path, downloadNames }): void => {
        // Assuming downloadNames are absolute paths
        const promiseArray = (!Array.isArray(downloadNames) ? [downloadNames] : downloadNames).map(
          async (downloadName): Promise<{ data: string; metadata: unknown }> => {
            const ref = storage.ref().child(downloadName);
            const data = await ref.getDownloadURL();
            let metadata;
            // Get metadata properties
            await ref
              .getMetadata()
              .then((item): void => {
                const { timeCreated } = item;
                metadata = {
                  timeCreated,
                };
              })
              .catch((error): void => {
                throw new Error(`Error in retrieving metadata: ${error.message}`);
              });
            return { data, metadata };
          },
        );
        Promise.all(promiseArray)
          .then((values): void => {
            // Zip the two arrays as the order of the promises is preserved
            const downloads = values.map((value, index): [string, { data: string; metadata: unknown }] => [
              Array.isArray(downloadNames) ? downloadNames[index] : downloadNames,
              value,
            ]);
            commit(GET_DOWNLOADS_SUCCESS, { path, downloads });
          })
          .catch((error): void => {
            // NOTE: if any of the images failed to download it throws
            commit(GET_DOWNLOADS_ERROR, { path, error });
          });
      });
    },
  },
} as Module<Downloads, State>;
