import { Route } from 'vue-router';

export default function (thisRoute: Route): boolean {
  const { path } = thisRoute.matched[0];
  return (
    path === '/:lang?/auth-verification' ||
    path === '/:lang?/login' ||
    path === '/:lang?/reset' ||
    path === '/:lang?/register'
  );
}
