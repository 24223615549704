import { Module } from 'vuex';
import { State } from '@/store/models';
import authModal from './authModal';
import idin from './idin';

import payment from './payment';
import investments from './investments';
import bids from './bids';
import bid from './bid';
import payments from './payments';
import assets from './assets';
import valuations from './valuations';
import identificationChecks from './identificationChecks';
import downloads from './downloads/downloads';
import checkout from './checkout';
import projects from './project';
import earnings from './earnings';
import costs from './costs';
import repayments from './repayments';
import customLogin from './customLogin';
import loginOrigin from './loginOrigin';

export default {
  idin,
  payment,
  bid,
  investments,
  bids,
  payments,
  assets,
  projects,
  valuations,
  identificationChecks,
  downloads,
  authModal,
  checkout,
  costs,
  earnings,
  repayments,
  customLogin,
  loginOrigin,
} as Record<string, Module<unknown, State>>;
