import { IdentificationCheckoutStepNames, Step } from '@/store/models/checkout';
import { clientConfig } from '@/helpers/clientData';

const whitelabelConfig = clientConfig();

const { modules, requireQuestionnaire } = whitelabelConfig || {};
const steps = whitelabelConfig.functionality.identificationRequest.steps;

export enum IdentCheckoutRouteNames {
  MAIN = 'checkoutIdentificationMain',
  IDIN = 'checkoutIdentificationIdin',
  WORLD = 'checkoutIdentificationWorld',
  BUSINESS = 'checkoutIdentificationBusiness',
}

/*
 * Steps in the checkout flow
 * The order is the same as it is displayed in
 * the identification and contract agreement are depended on the config in whitelabel.config
 * */
export const idenSteps: Step[] = [
  ...(modules.identification
    ? [
        {
          name: IdentificationCheckoutStepNames.Identification,
          route: '/:lang?/identification-checkout/identification/',
          display: 'settings.identification.identification',
        },
      ]
    : []),
  ...(requireQuestionnaire && steps.includes('questionnaire')
    ? [
        {
          name: IdentificationCheckoutStepNames.Questionnaire,
          route: '/:lang?/identification-checkout/questionnaire/',
          display: 'checkout.questionnaire.questionnaire',
        },
      ]
    : []),
  ...(requireQuestionnaire && steps.includes('riskQuestionnaire')
    ? [
        {
          name: IdentificationCheckoutStepNames.RiskQuestionnaire,
          route: '/:lang?/identification-checkout/riskquestionnaire/',
          display: 'checkout.riskquestionnaire.riskquestionnaire',
        },
      ]
    : []),
];
