import { Module, ActionContext } from 'vuex';
import to from 'await-to-js';
import { functions } from '@/firebase';
import { State } from '@/store/models';

const IDIN_TRANSACTION_ERROR = 'IDIN_TRANSACTION_ERROR';
const IDIN_TRANSACTION_SUCCESS = 'IDIN_TRANSACTION_SUCCESS';
const IDIN_TRANSACTION_PROCESSING = 'IDIN_TRANSACTION_PROCESSING';
const IDIN_GET_STATUS_ERROR = 'IDIN_GET_STATUS_ERROR';
const IDIN_GET_STATUS_PROCESSING = 'IDIN_GET_STATUS_PROCESSING';
const IDIN_GET_STATUS_SUCCESS = 'IDIN_GET_STATUS_SUCCESS';

export default {
  state: null,
  mutations: {},
  actions: {
    async idinTransaction(
      { commit }: ActionContext<State['idin'], State>,
      { issuerId, merchantReturnUrl }: { issuerId: string; merchantReturnUrl: string },
    ): Promise<void> {
      commit(IDIN_TRANSACTION_PROCESSING);
      const [idinTransactionError] = await to(
        functions.httpsCallable('idinInitRequest')({ issuerId, merchantReturnUrl }),
      );
      if (idinTransactionError) {
        commit(IDIN_TRANSACTION_ERROR, idinTransactionError);
      } else {
        commit(IDIN_TRANSACTION_SUCCESS);
      }
    },
    async idinGetStatus({ commit }: ActionContext<State['idin'], State>, payload: unknown): Promise<void> {
      commit(IDIN_GET_STATUS_PROCESSING);
      const [idinGetStatusError] = await to(functions.httpsCallable('idinGetStatus')(payload));
      if (idinGetStatusError) {
        commit(IDIN_GET_STATUS_ERROR, idinGetStatusError);
      } else {
        commit(IDIN_GET_STATUS_SUCCESS);
      }
    },
  },
  getters: {},
} as Module<State['idin'], State>;
