/**
 * Client configs.
 */

module.exports = {
  multiclient: {
    enabled: true,
    parent: 'shrfunders',
    bloqify_url: 'https://eves-bloqify-staging.firebaseapp.com',
  },
  mobilePhoneApp: false,
  currency: '€',
  logo: 'eves/eves.svg',
  logoAlt: 'eves/eves-alt.svg',
  logoShare: 'bloqhouse.png',
  logoPremium: 'eves-premium.svg',
  location: 'EUROPE-WEST1',
  internalClientName: 'eves',
  iconName1: 'favicon.ico',
  iconName2: 'favicon.svg',
  iconName3: 'favicon-512.png',
  scssVariables: './src/scss/variables/brands/_eves.scss',
  name: 'Eves',
  phone: '085 048 61 04',
  email: 'support@cyql.app',
  website: 'https://eves.nl/en/',
  glossary: true,
  langFileName: 'eves/language-config.ts',
  paymentServiceProvider: 'OPP',
  oppSource: 'asset',
  bloqhouseBrandingEnabled: false,
  pescheckEnabled: true,
  modules: {
    landing: false,
    identification: {
      default: true, // setting this to false requires manual changes,
      itsme: false,
    },
  },
  landingRedirect: '/all-campaigns',
  loginRedirect: '/account',
  intercom: false,
  requireContractAgreement: true,
  requireQuestionnaire: true,
  functionality: {
    enableMeerdervoortApi: false,
    tradingBoardVersion: 'one',
    idinGlobalSwitch: false,
    containerGlobalSwitch: false,
    progressEndpoint: false,
    loanEmail: false, // THIS IS MEERDERVOORT HARDCODED. KEEP DISABLED FOR ALL CLIENTS THAT ARE NOT MEERDERVOORT
    questionnaire: {
      folderName: 'shrfunders',
    },
    checkout: {
      initCheckoutStore: 'default',
      stylingFolderName: 'default',
      remoteCheckoutEnabled: false,
      steps: [
        'Identification',
        'Questionnaire',
        'RiskQuestionnaire',
        'Investment',
        'Terms',
        'PaymentMethods',
        'Payment',
      ],
      resetCheckoutExceptWhenAt: ['Terms', 'Questionnaire'],
      enableCheckoutQuestionnaire: true,
      checkoutInvestment: {
        showWithdrawal: true,
        folderName: 'default',
        firstParagraphEnabled: false,
        showMinimumAmount: false,
      },
      checkoutLegal: {
        enableLegalDoc: false,
        footerButtonText: 'checkout.legal.accept',
        footerButtonNoteText: '',
      },
      checkoutFooter: {
        rowJustifyContentEnabled: false,
        checkoutUpdateType: 'default',
      },
      checkoutQuestionnaire: {
        folderName: 'shrfunders',
      },
      checkoutRiskQuestionnaire: {
        folderName: 'shrfunders',
      },
      checkoutRecap: {
        finalisingPaymentTextEnabled: true,
      },
      checkoutStatus: {
        statusEnabled: true,
        titleEnabled: true,
        navigateToProjectButtonEnabled: true,
        showButtons: false,
      },
    },
    paymentInitTransaction: {
      loanTypeFundMax: false,
      containsLegalTerms: false,
      paymentInit: {
        fundTypeEnabled: false,
        signatureChoiceEnabled: false,
        legalDocsEnabled: false,
        questionaireQuestionAndAnswersEnabled: false,
        knowledgeQuestionAndAnswersEnabled: false,
      },
    },
    generateDividendsEarnings: 'generateDividendsPDFBROC',
    headerForPDF: false,
    customSignUpGreeting: '',
    index: {
      preloadHref: '"https://fonts.googleapis.com/css2?family=DM+Sans:wght@300;400;500;600;700&display=swap"',
      stylesheetHref: '"https://fonts.googleapis.com/css?family=DM+Sans:wght@300;400;500;600;700&display=swap"',
    },
    dividendsFormat: true,
    dividendsReturnsOptions: {
      checkIfAssetHasFixedDividends: true,
    },
    bannerLegal: {
      type: 'text',
      requireLegalBanner: true,
      legalBannerNoRequiresLicense: true,
      legalBannerPosition: 'top',
      phoneNumber: false,
    },
    navbar: {
      boxedEnabled: false,
      languageChoice: {
        enableLanguageChoice: true,
        languages: ['nl', 'en'],
        defaultLanguage: 'nl',
      },
      externalLink: 'https://www.weareeves.com/nl-NL',
      backgroundPrimary: false,
      containerEnabled: false,
      shadowEnabled: true,
      authPageRedirect: 'login',
      customNonLocalNonAuthLogin: false,
      logoSize: 'small',
      showDropDownMenu: true,
      navbarLinks: {
        allCampaigns: {
          visible: true,
        },
        knowledge: {
          visible: false,
          url: '',
        },
        home: {
          visible: false,
        },
      },
      navIcon: {
        homeIcon: true,
        projectsIcon: true,
      },
    },
    accountSettings: {
      salutation: true,
      initials: true,
      middleName: true,
      country: true,
    },
    accountDashboard: {
      holdingsImagePath: '@/assets/logos/shrfunders.svg',
      pendingPaymentsInfoboxEnabled: false,
    },
    accountBalance: {
      customImage: true,
      customWrapperClass: 'account__balance',
      animationsEnabled: false,
    },
    accountInvestments: {
      transactionAmountWrapperClassName: 'transactions-item-total',
      boughtSharesExcludeLoan: false,
      checkForPremium: true,
    },
    accountSingleFund: {
      availableProjectsTotal: 'available',
      textCheckFundType: false,
      displayInterest: false,
      overlayClassEnabled: false,
      totalsSectionEnabled: true,
      totalsSectionTwoEnabled: false,
      totalsSource: 'investment',
      dividendsSource: 'dividends',
      netValueCheckIfNoInvestments: false,
      includeIcon: false,
      completedBoxTextClassEnabled: true,
      premiumClassEnabled: true,
      paymentDateEarningsHistory: true,
    },
    property: {
      secondaryDocUnitedStates: false,
      secondaryDocThreshold: false,
      showContactAddress: false,
      availableNumbersEnabled: true,
      showInterestEnabled: false,
      transformedDateTimeEnabled: false,
      openCarousel: true,
      showDocsInReservation: true,
    },
    propertiesGridFund: {
      disableDocsIfNotInvested: false,
      contentsCardEnabled: true,
      subtitle: 'assetDescription',
    },
    properties: {
      toolbarSearchEnabled: false,
      dateSortingProperty: 'createdDateTime',
      titleEnabled: false,
    },
    identificationRequest: {
      imageFolderWorld: 'default/eves/world.svg',
      imageFolderBusiness: 'default/eves/company.svg',
      imageFolderWorldIcon: 'default/world-ico.svg',
      imageFolderBusinessIcon: 'default/company-ico.svg',
      steps: ['identification', 'questionnaire', 'riskQuestionnaire'],
      dateOfBirthFormat: 'DD/MM/YYYY',
      uploadPassport: true,
      businessRiskClassProp: false,
      businessExperienceProp: false,
      worldRiskClassProp: false,
      worldExperienceProp: false,
      businessInputs: {
        gender: {
          inputName: 'gender',
          rules: 'required',
          class: 'col--12 col-md--2',
          autocomplete: false,
          type: '',
          inputType: 'formSelect',
          options: 'genderOptions',
          label: 'common.gender',
        },
        firstName: {
          inputName: 'firstName',
          rules: 'required|alpha_spaces|max:40',
          class: 'col--12 col-md--5',
          autocomplete: true,
          type: '',
          label: 'common.firstName',
          inputType: 'formInput',
        },
        surname: {
          inputName: 'surname',
          rules: 'required|alpha_spaces|max:40',
          class: 'col--12 col-md--5',
          autocomplete: true,
          type: '',
          label: 'common.surname',
          inputType: 'formInput',
        },
        telephone: {
          inputName: 'telephone',
          rules: '',
          class: 'col--12 col-md--4',
          autocomplete: true,
          type: '',
          label: '',
          inputType: 'formTelephone',
        },
        separatedDateOfBirth: {
          inputName: 'separatedDateOfBirth',
          rules: '',
          class: '',
          autocomplete: false,
          type: '',
          inputType: 'separatedDateOfBirth',
          label: '',
        },
        placeOfBirth: {
          inputName: 'placeOfBirth',
          rules: 'required|alpha_spaces',
          class: 'col--12 col-md--6',
          autocomplete: false,
          type: '',
          label: 'common.placeOfBirth',
          inputType: 'formInput',
        },
        nationality: {
          inputName: 'nationality',
          rules: 'required',
          class: 'col--12 col-md--6',
          autocomplete: false,
          type: '',
          inputType: 'formSelect',
          options: 'countries',
          label: 'common.nationality',
        },
        address: {
          inputName: 'address',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'common.street',
          inputType: 'formInput',
        },
        houseNumber: {
          inputName: 'houseNumber',
          rules: 'required',
          class: 'col--12 col-md--4',
          autocomplete: false,
          type: '',
          label: 'common.number',
          inputType: 'formInput',
        },
        city: {
          inputName: 'city',
          rules: 'required',
          class: 'col--12 col-md--4',
          autocomplete: false,
          type: '',
          label: 'common.city',
          inputType: 'formInput',
        },
        postalCode: {
          inputName: 'postalCode',
          rules: 'required',
          class: 'col--12 col-md--4',
          autocomplete: false,
          type: '',
          label: 'common.postalCode',
          inputType: 'formInput',
        },
        country: {
          inputName: 'country',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          inputType: 'formSelect',
          options: 'countries',
          label: 'common.country',
        },
        companyName: {
          inputName: 'companyName',
          rules: 'required',
          class: 'col--12 col-md--6',
          autocomplete: false,
          type: '',
          label: 'common.companyName',
          inputType: 'formInput',
        },
        director: {
          inputName: 'director',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'common.nameOfDirector',
          inputType: 'formInput',
        },
        kvkNumber: {
          inputName: 'kvkNumber',
          rules: 'required|integer',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'identification.business.kvkNumber',
          inputType: 'formInput',
        },
        kvkImage: {
          inputName: 'kvkImage',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          inputType: 'formFileInput',
          label: 'identification.business.uploadYourKvKImage',
        },
        passport: {
          inputName: 'passport',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          inputType: 'formFileInput',
          label: 'identification.world.uploadYourPassport',
        },
      },
      worldInputs: {
        gender: {
          inputName: 'gender',
          rules: 'required',
          class: 'col--12 col-md--2',
          autocomplete: false,
          type: '',
          inputType: 'formSelect',
          options: 'genderOptions',
          label: 'common.gender',
        },
        firstName: {
          inputName: 'firstName',
          rules: 'required|alpha_spaces|max:40',
          class: 'col--12 col-md--5',
          autocomplete: true,
          type: '',
          label: 'common.firstName',
          inputType: 'formInput',
        },
        surname: {
          inputName: 'surname',
          rules: 'required|alpha_spaces|max:40',
          class: 'col--12 col-md--5',
          autocomplete: true,
          type: '',
          label: 'common.surname',
          inputType: 'formInput',
        },
        telephone: {
          inputName: 'telephone',
          rules: '',
          class: 'col--12 col-md--4',
          autocomplete: true,
          type: '',
          label: 'common.telephone',
          inputType: 'formTelephone',
        },
        separatedDateOfBirth: {
          inputName: 'separatedDateOfBirth',
          rules: '',
          class: '',
          autocomplete: false,
          type: '',
          inputType: 'separatedDateOfBirth',
          label: '',
        },
        placeOfBirth: {
          inputName: 'placeOfBirth',
          rules: 'required|alpha_spaces',
          class: 'col--12 col-md--6',
          autocomplete: false,
          type: '',
          label: 'common.placeOfBirth',
          inputType: 'formInput',
        },
        nationality: {
          inputName: 'nationality',
          rules: 'required',
          class: 'col--12 col-md--6',
          autocomplete: false,
          type: '',
          inputType: 'formSelect',
          options: 'countries',
          label: 'common.nationality',
        },
        city: {
          inputName: 'city',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'common.city',
          inputType: 'formInput',
        },
        address: {
          inputName: 'address',
          rules: 'required',
          class: 'col--12 col-md--4',
          autocomplete: false,
          type: '',
          label: 'common.address',
          inputType: 'formInput',
        },
        houseNumber: {
          inputName: 'houseNumber',
          rules: 'required',
          class: 'col--12 col-md--4',
          autocomplete: false,
          type: '',
          label: 'common.number',
          inputType: 'formInput',
        },
        postalCode: {
          inputName: 'postalCode',
          rules: 'required',
          class: 'col--12 col-md--4',
          autocomplete: false,
          type: '',
          label: 'common.postalCode',
          inputType: 'formInput',
        },
        country: {
          inputName: 'country',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          inputType: 'formSelect',
          options: 'countries',
          label: 'common.country',
        },
      },
    },
    identificationStep: {
      questionnaireEnabled: true,
    },
    identificationForm: {
      redirectToAccount: false,
      buttonText: 'dashboard.funds.myPortfolio',
    },
    settings: {
      bankAccountDetailsChangeEnabled: true,
      itemPaddingAdded: false,
      details: 'full',
      detailsChange: true,
      identification: true,
      settingsAutoReditect: 'details',
    },
    register: {
      privacyStatementUrl: 'https://www.broccoli.eu/nl/user-terms',
      enableLanguageSelect: true,
      newsletter: false,
      privacyStatement: true,
    },
    login: {
      enabled2FA: true,
    },
    authReset: {
      includePleaseCheckInboxInToast: true,
    },
    dropdownMenu: {
      dropdownMenuType: 'default',
      settingsNavigation: 'settings',
      dropdownMenuItems: {
        portfolio: {
          visible: true,
        },
        settings: {
          icon: 'cog',
          visible: true,
        },
      },
    },
    investor: {
      passportPropertyName: 'passport',
    },
    footer: {
      overridingName: 'Broccoli',
      type: 'long',
      longItems: {
        privacy: {
          url: 'https://broccoli.eu/en/privacy-statement',
          isInternal: false,
        },
        termsAndConditions: {
          url: 'https://broccoli.eu/en/general-conditions',
          isInternal: false,
        },
        cookies: {
          url: 'https://broccoli.eu/en/cookies',
          isInternal: false,
        },
        complaintsProcedure: {
          url: 'https://broccoli.eu/en/complaint-procedure',
          isInternal: false,
        },
        pricingAndPlacementProcedure: {
          url: 'https://broccoli.eu/en/pricing-and-placement-procedure',
          isInternal: false,
        },
      },
      brandLogo: 'extra-logo',
    },
  },
  emails: {
    from: 'Eves',
    userCreatedFromBloqadmin: {
      templateId: 'd-8a0c68bdd6b5402a8d87c29584e6545b',
    },
    sendSharesEmail: {
      format: 'extended',
    },
    sharesBought: {
      format: 'full',
      templateId: {
        en: 'd-28705deb4cc34cc08b48e3476786d303',
        nl: 'd-e6671cfdff304b589d1d3af51c968156',
      },
      enabled: true,
      signatureChoiceOnline: '',
      fromAdminTemplateId: '',
      sendlegalDocs: false,
    },
    sharesBoughtAdminNotification: {
      to: 'test@bloqhouse.com',
      templateId: '',
      enabled: false,
      signatureChoiceOnline: '',
    },
    verifyEmail: {
      enabled: true,
      templateId: {
        en: 'd-ee91c0026db14528b2620a5e0a9cbee6',
        nl: 'd-ec8822eef8d34896a56a40734f19c9a9',
      },
      format: 'subjectDirectorRegardsWelcomeActivate',
    },
    passwordReset: {
      enabled: true,
      templateId: {
        en: 'd-50bfe89fb0e3429a83afe44de0ab2775',
        nl: 'd-ca570d31c7e04d4c98387e3ee5687a04',
      },
      format: 'directorRegardsForgotReset',
    },
    identificationRequest: {
      approved: {
        templateId: {
          en: 'd-3a9643530b844bea8f30427dccca65ee',
          nl: 'd-3cac08dd29d84c04b555f073466e8fb2',
        },
      },
      rejected: {
        templateId: {
          en: 'd-82ea114672e94961aa5b09ee300b2c88',
          nl: 'd-43725bf4e897485cb550eddcbf8317c1 ',
        },
      },
      format: 'url',
    },
    completeBidEmailSeller: {
      enabled: true,
      templateId: {
        en: 'd-4c1308f42fd84604a08194b9f6f02306',
        nl: 'd-89e2ffe980c24d5193e3d23e34fe5045',
      },
    },
    completeBidEmailBuyer: {
      enabled: true,
      templateId: {
        en: 'd-8144e279bc514ef7a2e13d4fa91313df',
        nl: 'd-bb6ebc84be7e40b585de34e1acd712e2',
      },
    },
    createBid: {
      enabled: true,
      templateId: {
        en: 'd-75056d0897a149c4bf00d01e7afdb6dd',
        nl: 'd-01cef1e9fb5b4c0185ab8a7b410750f5',
      },
    },
    negotiateBid: {
      enabled: true,
      templateId: {
        en: 'd-bffd1631c049495cbad6b4ce57735649',
        nl: 'd-c4757d5a66a7489eb670d7bbbfdb7b61',
      },
    },
    requestBidPayment: {
      enabled: true,
      templateId: {
        en: 'd-376d72af929442ffad099241e46dadad',
        nl: 'd-8b16e907b92043e5980a4e751c79b4ff',
      },
    },
    sepaPaymentInstructions: {
      enabled: true,
      templateId: {
        en: 'd-67c1514f18cf44029cd213cf5590f393',
        nl: 'd-cf7b8f699aa341e49109d811d3caffdd',
      },
    },
    createIdentificationRequest: {
      fromClientName: false,
      toClientName: false,
      toClientEmail: [],
    },
    translations: {
      sharesBoughtInstrument: {
        subject: 'Obligaties',
        content: 'obligatiehouder',
      },
    },
    contactSupportByEmail: {
      from: 'support+1@broccoli.eu',
      to: 'support+1@broccoli.eu',
      cc: false,
    },
    reservationEmail: {
      verified: {
        templateId: {
          en: 'd-2a3eec64ed7a4a289f07b66ba65ebc58',
          nl: 'd-5f6d17b591024db7979040bbb1fab476',
        },
      },
      unverified: {
        templateId: {
          en: 'd-35f34079c7f84fd192f3b6426e3ceed1',
          nl: 'd-9f9c7b7ad3e048a8a8d4cfaae314379c',
        },
      },
    },
  },
};
