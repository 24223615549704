import { Module } from 'vuex';
import { State } from '@/store/models';
import { DefaultBid as Bid } from '@/store/models/bid';
import { getId } from '@/helpers/utils';

export default {
  state: [],
  mutations: {},
  actions: {},
  getters: {
    getBidsByInvestmentId:
      (state): ((id: string) => Bid[] | undefined) =>
      (investmentId: string): Bid[] | undefined =>
        state.filter((bid): boolean => getId(bid.investment) === investmentId),
    getBidById:
      (state): ((id: string) => Bid | undefined) =>
      (id: string): Bid | undefined =>
        state.find((bid): boolean => bid.id === id),
  },
} as Module<Bid[], State>;
