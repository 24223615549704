import { firebase } from '@/firebase';
import { Module } from 'vuex';
import BigNumber from 'bignumber.js';
import { State } from '@/store/models';
import { Payment, PaymentStatus, BuyingPayment } from '@/store/models/investment';
import { formatNumber } from '@/filters/number';
import { getId } from '@/helpers/utils';

export interface InvestmentsArray<T> extends Array<T> {
  totalLength?: number;
}

export interface CombinedDividendsFormat {
  euroAmountDividends: number;
  dividendsFormat: [string, number];
}

/**
 * (objId: string) => unknown that shows two decimals if there are any
 * @param numb
 */
const showTwoDecimalsOrNone = (numb): number => (numb % 1 !== 0 ? Number(formatNumber(numb, 2)) : numb);

// get the paymentDate if paymentDate itself is undefined
export const getPaymentDate = (payment: Payment): firebase.firestore.Timestamp =>
  payment.paymentDateTime ?? payment.updatedDateTime ?? payment.createdDateTime;

export default {
  state: [],
  mutations: {},
  actions: {},
  getters: {
    getPaymentById:
      (state): ((paymentId: string) => unknown) =>
      (paymentId: string): Payment | undefined =>
        state.find((payment): boolean => payment.id === paymentId),
    getPaymentByAsset:
      (state): ((assetId: string) => unknown) =>
      (assetId: string): Payment | undefined =>
        state.find((payment): boolean => getId(payment.asset) === assetId),
    getPaymentsByAsset:
      (state): ((assetId: string) => unknown) =>
      (assetId: string): Payment[] | undefined =>
        state.filter((payment): boolean => getId(payment.asset) === assetId),
    getPaymentsByInvestmentId:
      (state): ((investmentId: string) => unknown) =>
      (investmentId: string): Payment[] =>
        state.filter((payment): boolean => getId(payment.investment) === investmentId && !payment.deleted),
    getPaidPaymentsByInvestmentId:
      (state): ((investmentId: string) => unknown) =>
      (investmentId: string): Payment[] =>
        state
          .filter(
            (payment): boolean =>
              getId(payment.investment) === investmentId &&
              payment.providerData.status === PaymentStatus.Paid &&
              !payment.deleted,
          )
          .sort((a, b): number => getPaymentDate(b).toMillis() - getPaymentDate(a).toMillis()),
    getPaidOrOpenPaymentsByInvestmentId:
      (state): ((investmentId: string) => unknown) =>
      (investmentId: string): Payment[] =>
        state
          .filter(
            (payment): boolean =>
              getId(payment.investment) === investmentId &&
              (payment.providerData.status === PaymentStatus.Paid ||
                payment.providerData.status === PaymentStatus.Open) &&
              !payment.deleted,
          )
          .sort((a, b): number => getPaymentDate(b).toMillis() - getPaymentDate(a).toMillis()),
    getPaidPayments: (state): Payment[] =>
      state.filter((payment): boolean => payment.providerData.status === PaymentStatus.Paid),
    getPaidOrOpenPayments: (state): Payment[] =>
      state.filter(
        (payment): boolean =>
          payment.providerData.status === PaymentStatus.Paid || payment.providerData.status === PaymentStatus.Open,
      ),
    getOpenPayments: (state): Payment[] =>
      state.filter((payment): boolean => payment.providerData.status === PaymentStatus.Open),
    getOpenOrRequestedPayments: (state): Payment[] =>
      state.filter(
        (payment): boolean =>
          payment.providerData.status === PaymentStatus.Open || payment.providerData.status === PaymentStatus.Requested,
      ),
    getPaidOpenRequestedPayments: (state): Payment[] =>
      state.filter(
        (payment): boolean =>
          payment.providerData.status === PaymentStatus.Open ||
          payment.providerData.status === PaymentStatus.Paid ||
          payment.providerData.status === PaymentStatus.Requested,
      ),
    // Get the number of investments that have at least one paid payment
    getLengthPaidPayments: (state): number =>
      state.filter((payment): boolean => payment.providerData.status === PaymentStatus.Paid).length,
    getDividendsByAssetYearly:
      (state): ((assetId: string) => unknown) =>
      (assetId: string): [string, number][] =>
        state.reduce(
          (divByAsset, payment): [string, number][] => {
            const tempDivByAsset = [...divByAsset];
            if (getId(payment.asset) === assetId) {
              tempDivByAsset.push([
                payment.dividendsFormat[0],
                showTwoDecimalsOrNone(
                  new BigNumber(payment.dividendsFormat[1])
                    .dividedBy(100)
                    .times(payment.providerData.metadata.euroAmount)
                    .toNumber(),
                ),
              ]);
            }
            return divByAsset;
          },
          [] as [string, number][],
        ),
    getTotalDividendsPerYear: (state, getters): number => {
      const result = getters.getPaidPayments
        .reduce((paymentA, paymentB): BigNumber => {
          if ((paymentB.scheduleEnding && paymentB.ended && !paymentB.deleted) || !paymentB.ended) {
            return new BigNumber(paymentB.dividendsFormat[1])
              .dividedBy(100)
              .times(paymentB.providerData.metadata.euroAmount)
              .plus(paymentA);
          }
          return paymentA;
        }, new BigNumber(0))
        .toNumber();

      // Only 2 decimals
      return showTwoDecimalsOrNone(result);
    },
    getTotalDividendsPerMonth: (state, getters): number => {
      const result = new BigNumber(getters.getTotalDividendsPerYear).dividedBy(12).toNumber();

      return showTwoDecimalsOrNone(result);
    },
    getInvestedSharesAvailable:
      (state): ((assetType: string) => unknown) =>
      (assetId: string): number => {
        let total = 0;
        state
          .filter((payment: Payment): boolean => getId(payment.asset) === assetId)
          .forEach((row: Payment): void => {
            if (
              row.providerData.metadata.sharesAmount > 0 &&
              row.providerData.status === PaymentStatus.Paid &&
              !row.ended
            ) {
              const usedShares = ((row as BuyingPayment).usedShares || []).reduce(
                (acum, next): number => acum + next.sharesAmount,
                0,
              );
              total += row.providerData.metadata.sharesAmount - usedShares;
            }
          });
        return total;
      },
    getTotalDividendsPerQuarter: (state, getters): number => {
      const result = new BigNumber(getters.getTotalDividendsPerYear).dividedBy(4).toNumber();

      return showTwoDecimalsOrNone(result);
    },
    getTotalDividendsPerYearByAssetType:
      (state, getters): ((assetType: string) => unknown) =>
      (assetType: string): number => {
        const result = (getters.getPaidPayments as Payment[])
          .filter((payment): boolean => getters.getAssetById(getId(payment.asset))?.fundType === assetType)
          .reduce((paymentA, paymentB): BigNumber => {
            if ((paymentB.scheduleEnding && paymentB.ended && !paymentB.deleted) || !paymentB.ended) {
              return new BigNumber(paymentB.dividendsFormat[1])
                .dividedBy(100)
                .times(paymentB.providerData.metadata.euroAmount)
                .plus(paymentA);
            }

            return paymentA;
          }, new BigNumber(0))
          .toNumber();
        // Only 2 decimals
        return showTwoDecimalsOrNone(result);
      },
    getTotalDividendsPerYearByAssetType2:
      (state, getters): ((assetType: string) => unknown) =>
      (assetType: string): number => {
        const result = (getters.getPaidPayments as Payment[])
          .filter((payment): boolean => getters.getAssetById(getId(payment.asset))?.fundType === assetType)
          .reduce((paymentA, paymentB): BigNumber => {
            if ((paymentB.scheduleEnding && paymentB.ended && !paymentB.deleted) || !paymentB.ended) {
              return new BigNumber(paymentB.dividendsFormat[1])
                .dividedBy(100)
                .times(paymentB.providerData.metadata.euroAmount)
                .plus(paymentA);
            }

            return paymentA;
          }, new BigNumber(0))
          .toNumber();

        // Only 2 decimals
        return showTwoDecimalsOrNone(result);
      },
    getTotalDividendsPerMonthByAssetType:
      (state, getters): ((assetType: string) => unknown) =>
      (assetType: string): number => {
        const result = new BigNumber(getters.getTotalDividendsPerYearByAssetType(assetType)).dividedBy(12).toNumber();
        // Only 2 decimals
        return showTwoDecimalsOrNone(result);
      },
    getTotalDividendsPerQuarterByAssetType:
      (state, getters): ((assetType: string) => unknown) =>
      (assetType: string): number => {
        const result = new BigNumber(getters.getTotalDividendsPerYearByAssetType(assetType)).dividedBy(4).toNumber();
        // Only 2 decimals
        return showTwoDecimalsOrNone(result);
      },
    getTotalEquityDividendsPerYear: (state, getters): number => {
      const equityPayments = getters.getPaidPayments.filter(
        (payment): boolean => getters.getAssetById(getId(payment.asset))?.fundType === 'equity',
      );
      const result = equityPayments
        .reduce((paymentA, paymentB): BigNumber => {
          if (!paymentB.ended) {
            return new BigNumber(paymentB.dividendsFormat[1])
              .dividedBy(100)
              .times(paymentB.providerData.metadata.euroAmount)
              .plus(paymentA);
          }

          return paymentA;
        }, new BigNumber(0))
        .toNumber();

      // Only 2 decimals
      return showTwoDecimalsOrNone(result);
    },
    getTotalEquityDividendsPerMonth: (state, getters): number => {
      const result = new BigNumber(getters.getTotalEquityDividendsPerYear).dividedBy(12).toNumber();

      return showTwoDecimalsOrNone(result);
    },
    getTotalBondDividendsPerYear: (state, getters): number => {
      const bondPayments = getters.getPaidPayments.filter(
        (payment): boolean => getters.getAssetById(getId(payment.asset))?.fundType === 'bond',
      );
      const result = bondPayments
        .reduce((paymentA, paymentB): BigNumber => {
          if (!paymentB.ended) {
            new BigNumber(paymentB.dividendsFormat[1])
              .dividedBy(100)
              .times(paymentB.providerData.metadata.euroAmount)
              .plus(paymentA);
          }

          return paymentA;
        }, new BigNumber(0))
        .toNumber();

      // Only 2 decimals
      return showTwoDecimalsOrNone(result);
    },
    getTotalBondDividendsPerMonth: (state, getters): number => {
      const result = new BigNumber(getters.getTotalBondDividendsPerYear).dividedBy(12).toNumber();

      return showTwoDecimalsOrNone(result);
    },
    getTotalLoanDividendsPerYear: (state, getters): number => {
      const loanPayments = getters.getPaidPayments.filter(
        (payment): boolean => getters.getAssetById(getId(payment.asset))?.fundType === 'loan',
      );
      const result = loanPayments
        .reduce((paymentA, paymentB): BigNumber => {
          if (!paymentB.ended) {
            return new BigNumber(paymentB.dividendsFormat[1])
              .dividedBy(100)
              .times(paymentB.providerData.metadata.euroAmount)
              .plus(paymentA);
          }

          return paymentA;
        }, new BigNumber(0))
        .toNumber();

      // Only 2 decimals
      return showTwoDecimalsOrNone(result);
    },
    getTotalLoanDividendsPerMonth: (state, getters): number => {
      const result = new BigNumber(getters.getTotalLoanDividendsPerYear).dividedBy(12).toNumber();

      return showTwoDecimalsOrNone(result);
    },
    hasDifferentPaymentFormatsByAsset:
      (state): ((objId: string) => unknown) =>
      (assetId: string): boolean => {
        const dividendFormats: Payment['dividendsFormat'][] = [];
        let whileIndex = 0;
        while (dividendFormats.length < 2 && whileIndex < state.length) {
          const payment = state[whileIndex];
          if (getId(payment.asset) === assetId) {
            if (
              !dividendFormats.some(
                (format): boolean =>
                  payment.dividendsFormat[0] === format[0] && payment.dividendsFormat[1] === format[1],
              )
            ) {
              dividendFormats.push(payment.dividendsFormat);
            }
          }
          whileIndex++;
        }
        return dividendFormats.length > 1;
      },
    // keeping in case needed for the future which is a fair probability
    getCombinedDividendsFormatByAsset:
      (state, getters): ((objId: string) => unknown) =>
      (assetId: string, allPayments?: boolean): CombinedDividendsFormat => {
        const payments = !allPayments ? getters.getPaidPayments : getters.getPaidOpenRequestedPayments;
        const group: CombinedDividendsFormat = payments.reduce((a, b): CombinedDividendsFormat => {
          if (getId(b.asset) !== assetId && (!b.ended || (b.ended && b.scheduleEnding))) {
            return a;
          }
          // Creating the object with the next payment
          const final: CombinedDividendsFormat = {
            ...a,
            [b.dividendsFormat[0]]: {
              euroAmount: b.providerData.metadata.euroAmount,
              sharesAmount: b.providerData.metadata.sharesAmount,
              percentage: b.dividendsFormat[1],
              dividends: new BigNumber(b.providerData.metadata.euroAmount)
                .multipliedBy(b.dividendsFormat[1])
                .dividedBy(100)
                .toNumber(),
            },
          };
          // Doing the actual reduction
          if (a[b.dividendsFormat[0]]) {
            final[b.dividendsFormat[0]].euroAmount = new BigNumber(final[b.dividendsFormat[0]].euroAmount)
              .plus(a[b.dividendsFormat[0]].euroAmount)
              .toNumber();
            final[b.dividendsFormat[0]].sharesAmount = new BigNumber(final[b.dividendsFormat[0]].sharesAmount)
              .plus(a[b.dividendsFormat[0]].sharesAmount)
              .toNumber();
            final[b.dividendsFormat[0]].dividends = new BigNumber(final[b.dividendsFormat[0]].dividends)
              .plus(a[b.dividendsFormat[0]].dividends)
              .toNumber();
          }
          return final;
        }, {});

        return group;
      },
    getCombinedDividendsFormatByAssetPaidAndOpen:
      (state, getters): ((assetId: string, investmentId: string) => CombinedDividendsFormat) =>
      (assetId: string, investmentId): CombinedDividendsFormat => {
        const group: CombinedDividendsFormat = getters
          .getPaidOrOpenPaymentsByInvestmentId(investmentId)
          .reduce((a, b): unknown => {
            if (getId(b.asset) !== assetId && !b.ended) {
              return a;
            }

            // Adding a check to ensure b.dividendsFormat is indeed an array or iterable
            if (Array.isArray(b.dividendsFormat)) {
              return [...a, ...b.dividendsFormat];
            } else {
              return [a];
            }
          }, []);
        return group;
      },

    getCombinedExpectedReturnPerDividendFormat:
      (state, getters): ((objId: string, divFormat) => number | undefined) =>
      (investmentId, divFormat): number | undefined => {
        const payments = getters.getPaidOrOpenPaymentsByInvestmentId(investmentId);
        if (payments.length) {
          const sumEuroAmount = payments
            .reduce((accumulator, payment): BigNumber => {
              if (
                payment.dividendsFormat[0] === divFormat[0] &&
                payment.dividendsFormat[1] === divFormat[1] &&
                !payment.ended &&
                !payment.deleted
              ) {
                return accumulator.plus(payment.providerData.metadata.euroAmount);
              }
              return accumulator;
            }, new BigNumber(0))
            .toNumber();
          return new BigNumber(sumEuroAmount).multipliedBy(divFormat[1]).dividedBy(100).toNumber();
        }
        return undefined;
      },
  },
} as Module<Payment[], State>;
