import Vue from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';

// Solid icons
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons/faArrowUp';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons/faArrowDown';
import { faArrowCircleUp } from '@fortawesome/free-solid-svg-icons/faArrowCircleUp';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt';
import { faCog } from '@fortawesome/free-solid-svg-icons/faCog';
import { faTachometerAlt } from '@fortawesome/free-solid-svg-icons/faTachometerAlt';
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons/faExchangeAlt';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons/faLayerGroup';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faEquals } from '@fortawesome/free-solid-svg-icons/faEquals';
import { faEuroSign } from '@fortawesome/free-solid-svg-icons/faEuroSign';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons/faSyncAlt';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faInfo } from '@fortawesome/free-solid-svg-icons/faInfo';
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock';
import { faPen } from '@fortawesome/free-solid-svg-icons/faPen';
import { faMobile } from '@fortawesome/free-solid-svg-icons/faMobile';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { faUniversity } from '@fortawesome/free-solid-svg-icons/faUniversity';
import { faWallet } from '@fortawesome/free-solid-svg-icons/faWallet';
import { faQuestion } from '@fortawesome/free-solid-svg-icons/faQuestion';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons/faQuestionCircle';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { faUserCog } from '@fortawesome/free-solid-svg-icons/faUserCog';
import { faCoins } from '@fortawesome/free-solid-svg-icons/faCoins';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { faSortAmountDown } from '@fortawesome/free-solid-svg-icons/faSortAmountDown';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone';
import { faFile } from '@fortawesome/free-solid-svg-icons/faFile';
import { faCopyright } from '@fortawesome/free-solid-svg-icons/faCopyright';
import { faClock } from '@fortawesome/free-solid-svg-icons/faClock';
import { faFileSignature } from '@fortawesome/free-solid-svg-icons/faFileSignature';
import { faSearchPlus } from '@fortawesome/free-solid-svg-icons/faSearchPlus';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons/faPaperPlane';
import { faLifeRing } from '@fortawesome/free-solid-svg-icons/faLifeRing';
import { faRedoAlt } from '@fortawesome/free-solid-svg-icons/faRedoAlt';
import { faBuilding } from '@fortawesome/free-solid-svg-icons/faBuilding';
import { faBook } from '@fortawesome/free-solid-svg-icons/faBook';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons/faMapMarkerAlt';
import { faStar } from '@fortawesome/free-solid-svg-icons/faStar';
import { faGlobe } from '@fortawesome/free-solid-svg-icons/faGlobe';
import { faList } from '@fortawesome/free-solid-svg-icons/faList';
import { faThLarge } from '@fortawesome/free-solid-svg-icons/faThLarge';
import { faHouse } from '@fortawesome/free-solid-svg-icons/faHouse';
import { faFileArchive } from '@fortawesome/free-solid-svg-icons/faFileArchive';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { faFolder } from '@fortawesome/free-solid-svg-icons/faFolder';
import { faCreditCardAlt } from '@fortawesome/free-solid-svg-icons/faCreditCardAlt';

// Add icons to library
library.add(
  // @ts-expect-error - ToDo: fix this error
  faChevronRight,
  faChevronLeft,
  faChevronUp,
  faChevronDown,
  faArrowUp,
  faArrowDown,
  faArrowCircleUp,
  faExternalLinkAlt,
  faSignOutAlt,
  faCog,
  faTachometerAlt,
  faExchangeAlt,
  faLayerGroup,
  faCheckCircle,
  faCheck,
  faEquals,
  faEuroSign,
  faFile,
  faCopyright,
  faTimesCircle,
  faTimes,
  faSyncAlt,
  faInfoCircle,
  faInfo,
  faPen,
  faLock,
  faMobile,
  faUser,
  faUniversity,
  faWallet,
  faQuestion,
  faQuestionCircle,
  faPlus,
  faMinus,
  faDownload,
  faUserCog,
  faCoins,
  faExclamationTriangle,
  faExclamationCircle,
  faSortAmountDown,
  faSpinner,
  faEnvelope,
  faPhone,
  faClock,
  faFileSignature,
  faSearchPlus,
  faPaperPlane,
  faLifeRing,
  faRedoAlt,
  faBuilding,
  faBook,
  faMapMarkerAlt,
  faStar,
  faGlobe,
  faList,
  faThLarge,
  faHouse,
  faFileArchive,
  faEye,
  faFolder,
  faCreditCardAlt,
);

Vue.component('FontAwesomeIcon', FontAwesomeIcon);
