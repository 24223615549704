import { Module } from 'vuex';
import { LoginOrigin, State } from '@/store/models';

export default {
  state: {
    originIsLogin: false,
  },
  mutations: {
    setLoginOrigin(state, { originIsLogin }: { originIsLogin: boolean }): void {
      state.originIsLogin = originIsLogin;
    },
  },
  actions: {
    setLoginOrigin({ commit }, { originIsLogin }: { originIsLogin: boolean }): void {
      commit('setLoginOrigin', {
        originIsLogin,
      });
    },
  },
  getters: {
    userComesFromLogin: (state): boolean => state.originIsLogin,
  },
} as Module<LoginOrigin, State>;
