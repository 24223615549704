/**
 * Client configs.
 */

module.exports = {
  multiclient: {
    enabled: true,
    parent: 'shrfunders',
    bloqify_url: 'https://cyql-bloqify-staging.firebaseapp.com',
  },
  mobilePhoneApp: false,
  currency: '€',
  logo: 'cyql/cyql.svg',
  logoAlt: 'cyql/cyql-alt.svg',
  logoShare: 'bloqhouse.png',
  logoPremium: 'cyql-premium.svg',
  location: 'EUROPE-WEST1',
  internalClientName: 'cyql',
  iconName1: 'favicon.ico',
  iconName2: 'favicon.svg',
  iconName3: 'favicon-512.png',
  scssVariables: './src/scss/variables/brands/_cyql.scss',
  name: 'Cyql',
  phone: '085 048 61 04',
  email: 'support@cyql.app',
  website: 'https://cyql.nl/en/',
  glossary: true,
  langFileName: 'cyql/language-config.ts',
  paymentServiceProvider: 'OPP',
  oppSource: 'asset',
  bloqhouseBrandingEnabled: false,
  pescheckEnabled: true,
  modules: {
    landing: false,
    identification: {
      default: true, // setting this to false requires manual changes,
      itsme: false,
    },
  },
  landingRedirect: '/all-campaigns',
  loginRedirect: '/account',
  intercom: false,
  requireContractAgreement: true,
  requireQuestionnaire: true,
  functionality: {
    enableMeerdervoortApi: false,
    tradingBoardVersion: 'one',
    idinGlobalSwitch: false,
    containerGlobalSwitch: false,
    progressEndpoint: false,
    loanEmail: false, // THIS IS MEERDERVOORT HARDCODED. KEEP DISABLED FOR ALL CLIENTS THAT ARE NOT MEERDERVOORT
    questionnaire: {
      folderName: 'shrfunders',
    },
    checkout: {
      initCheckoutStore: 'default',
      stylingFolderName: 'default',
      remoteCheckoutEnabled: false,
      steps: [
        'Identification',
        'Questionnaire',
        'RiskQuestionnaire',
        'Investment',
        'Terms',
        'PaymentMethods',
        'Payment',
      ],
      resetCheckoutExceptWhenAt: ['Terms', 'Questionnaire'],
      enableCheckoutQuestionnaire: true,
      checkoutInvestment: {
        showWithdrawal: true,
        folderName: 'default',
        firstParagraphEnabled: false,
        showMinimumAmount: false,
      },
      checkoutLegal: {
        enableLegalDoc: false,
        footerButtonText: 'checkout.legal.accept',
        footerButtonNoteText: '',
      },
      checkoutFooter: {
        rowJustifyContentEnabled: false,
        checkoutUpdateType: 'default',
      },
      checkoutQuestionnaire: {
        folderName: 'shrfunders',
      },
      checkoutRiskQuestionnaire: {
        folderName: 'shrfunders',
      },
      checkoutRecap: {
        finalisingPaymentTextEnabled: true,
      },
      checkoutStatus: {
        statusEnabled: true,
        titleEnabled: true,
        navigateToProjectButtonEnabled: true,
        showButtons: false,
      },
    },
    paymentInitTransaction: {
      loanTypeFundMax: false,
      containsLegalTerms: false,
      paymentInit: {
        fundTypeEnabled: false,
        signatureChoiceEnabled: false,
        legalDocsEnabled: false,
        questionaireQuestionAndAnswersEnabled: false,
        knowledgeQuestionAndAnswersEnabled: false,
      },
    },
    generateDividendsEarnings: 'generateDividendsPDFBROC',
    headerForPDF: false,
    customSignUpGreeting: '',
    index: {
      preloadHref: '"https://fonts.googleapis.com/css2?family=DM+Sans:wght@300;400;500;600;700&display=swap"',
      stylesheetHref: '"https://fonts.googleapis.com/css?family=DM+Sans:wght@300;400;500;600;700&display=swap"',
    },
    dividendsFormat: true,
    dividendsReturnsOptions: {
      checkIfAssetHasFixedDividends: true,
    },
    bannerLegal: {
      type: 'text',
      requireLegalBanner: true,
      legalBannerNoRequiresLicense: true,
      legalBannerPosition: 'top',
      phoneNumber: false,
    },
    navbar: {
      boxedEnabled: false,
      languageChoice: {
        enableLanguageChoice: true,
        languages: ['nl', 'en'],
        defaultLanguage: 'nl',
      },
      backgroundPrimary: false,
      containerEnabled: false,
      shadowEnabled: true,
      authPageRedirect: 'login',
      customNonLocalNonAuthLogin: false,
      logoSize: 'small',
      showDropDownMenu: true,
      navbarLinks: {
        allCampaigns: {
          visible: true,
        },
        knowledge: {
          visible: false,
          url: '',
        },
        home: {
          visible: false,
        },
      },
      navIcon: {
        homeIcon: true,
        projectsIcon: true,
      },
    },
    accountSettings: {
      salutation: true,
      initials: true,
      middleName: true,
      country: true,
    },
    accountDashboard: {
      holdingsImagePath: '@/assets/logos/shrfunders.svg',
      pendingPaymentsInfoboxEnabled: false,
    },
    accountBalance: {
      customImage: true,
      customWrapperClass: 'account__balance',
      animationsEnabled: false,
    },
    accountInvestments: {
      transactionAmountWrapperClassName: 'transactions-item-total',
      boughtSharesExcludeLoan: false,
      checkForPremium: true,
    },
    accountSingleFund: {
      availableProjectsTotal: 'available',
      textCheckFundType: false,
      displayInterest: false,
      overlayClassEnabled: false,
      totalsSectionEnabled: true,
      totalsSectionTwoEnabled: false,
      totalsSource: 'investment',
      dividendsSource: 'dividends',
      netValueCheckIfNoInvestments: false,
      includeIcon: false,
      completedBoxTextClassEnabled: true,
      premiumClassEnabled: true,
      paymentDateEarningsHistory: true,
    },
    property: {
      secondaryDocUnitedStates: false,
      secondaryDocThreshold: false,
      showContactAddress: false,
      availableNumbersEnabled: true,
      showInterestEnabled: false,
      transformedDateTimeEnabled: false,
      openCarousel: true,
      showDocsInReservation: true,
    },
    propertiesGridFund: {
      disableDocsIfNotInvested: false,
      contentsCardEnabled: true,
      subtitle: 'assetDescription',
    },
    properties: {
      toolbarSearchEnabled: false,
      dateSortingProperty: 'createdDateTime',
      titleEnabled: false,
    },
    identificationRequest: {
      imageFolderWorld: 'default/cyql/world.svg',
      imageFolderBusiness: 'default/cyql/company.svg',
      imageFolderWorldIcon: 'default/world-ico.svg',
      imageFolderBusinessIcon: 'default/company-ico.svg',
      steps: ['identification', 'questionnaire', 'riskQuestionnaire'],
      dateOfBirthFormat: 'DD/MM/YYYY',
      uploadPassport: true,
      businessRiskClassProp: false,
      businessExperienceProp: false,
      worldRiskClassProp: false,
      worldExperienceProp: false,
      businessInputs: {
        gender: {
          inputName: 'gender',
          rules: 'required',
          class: 'col--12 col-md--2',
          autocomplete: false,
          type: '',
          inputType: 'formSelect',
          options: 'genderOptions',
          label: 'common.gender',
        },
        firstName: {
          inputName: 'firstName',
          rules: 'required|alpha_spaces|max:40',
          class: 'col--12 col-md--5',
          autocomplete: true,
          type: '',
          label: 'common.firstName',
          inputType: 'formInput',
        },
        surname: {
          inputName: 'surname',
          rules: 'required|alpha_spaces|max:40',
          class: 'col--12 col-md--5',
          autocomplete: true,
          type: '',
          label: 'common.surname',
          inputType: 'formInput',
        },
        telephone: {
          inputName: 'telephone',
          rules: '',
          class: 'col--12 col-md--4',
          autocomplete: true,
          type: '',
          label: '',
          inputType: 'formTelephone',
        },
        separatedDateOfBirth: {
          inputName: 'separatedDateOfBirth',
          rules: '',
          class: '',
          autocomplete: false,
          type: '',
          inputType: 'separatedDateOfBirth',
          label: '',
        },
        placeOfBirth: {
          inputName: 'placeOfBirth',
          rules: 'required|alpha_spaces',
          class: 'col--12 col-md--6',
          autocomplete: false,
          type: '',
          label: 'common.placeOfBirth',
          inputType: 'formInput',
        },
        nationality: {
          inputName: 'nationality',
          rules: 'required',
          class: 'col--12 col-md--6',
          autocomplete: false,
          type: '',
          inputType: 'formSelect',
          options: 'countries',
          label: 'common.nationality',
        },
        address: {
          inputName: 'address',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'common.street',
          inputType: 'formInput',
        },
        houseNumber: {
          inputName: 'houseNumber',
          rules: 'required',
          class: 'col--12 col-md--4',
          autocomplete: false,
          type: '',
          label: 'common.number',
          inputType: 'formInput',
        },
        city: {
          inputName: 'city',
          rules: 'required',
          class: 'col--12 col-md--4',
          autocomplete: false,
          type: '',
          label: 'common.city',
          inputType: 'formInput',
        },
        postalCode: {
          inputName: 'postalCode',
          rules: 'required',
          class: 'col--12 col-md--4',
          autocomplete: false,
          type: '',
          label: 'common.postalCode',
          inputType: 'formInput',
        },
        country: {
          inputName: 'country',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          inputType: 'formSelect',
          options: 'countries',
          label: 'common.country',
        },
        companyName: {
          inputName: 'companyName',
          rules: 'required',
          class: 'col--12 col-md--6',
          autocomplete: false,
          type: '',
          label: 'common.companyName',
          inputType: 'formInput',
        },
        director: {
          inputName: 'director',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'common.nameOfDirector',
          inputType: 'formInput',
        },
        kvkNumber: {
          inputName: 'kvkNumber',
          rules: 'required|integer',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'identification.business.kvkNumber',
          inputType: 'formInput',
        },
        kvkImage: {
          inputName: 'kvkImage',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          inputType: 'formFileInput',
          label: 'identification.business.uploadYourKvKImage',
        },
        passport: {
          inputName: 'passport',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          inputType: 'formFileInput',
          label: 'identification.world.uploadYourPassport',
        },
      },
      worldInputs: {
        gender: {
          inputName: 'gender',
          rules: 'required',
          class: 'col--12 col-md--2',
          autocomplete: false,
          type: '',
          inputType: 'formSelect',
          options: 'genderOptions',
          label: 'common.gender',
        },
        firstName: {
          inputName: 'firstName',
          rules: 'required|alpha_spaces|max:40',
          class: 'col--12 col-md--5',
          autocomplete: true,
          type: '',
          label: 'common.firstName',
          inputType: 'formInput',
        },
        surname: {
          inputName: 'surname',
          rules: 'required|alpha_spaces|max:40',
          class: 'col--12 col-md--5',
          autocomplete: true,
          type: '',
          label: 'common.surname',
          inputType: 'formInput',
        },
        telephone: {
          inputName: 'telephone',
          rules: '',
          class: 'col--12 col-md--4',
          autocomplete: true,
          type: '',
          label: 'common.telephone',
          inputType: 'formTelephone',
        },
        separatedDateOfBirth: {
          inputName: 'separatedDateOfBirth',
          rules: '',
          class: '',
          autocomplete: false,
          type: '',
          inputType: 'separatedDateOfBirth',
          label: '',
        },
        placeOfBirth: {
          inputName: 'placeOfBirth',
          rules: 'required|alpha_spaces',
          class: 'col--12 col-md--6',
          autocomplete: false,
          type: '',
          label: 'common.placeOfBirth',
          inputType: 'formInput',
        },
        nationality: {
          inputName: 'nationality',
          rules: 'required',
          class: 'col--12 col-md--6',
          autocomplete: false,
          type: '',
          inputType: 'formSelect',
          options: 'countries',
          label: 'common.nationality',
        },
        city: {
          inputName: 'city',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'common.city',
          inputType: 'formInput',
        },
        address: {
          inputName: 'address',
          rules: 'required',
          class: 'col--12 col-md--4',
          autocomplete: false,
          type: '',
          label: 'common.address',
          inputType: 'formInput',
        },
        houseNumber: {
          inputName: 'houseNumber',
          rules: 'required',
          class: 'col--12 col-md--4',
          autocomplete: false,
          type: '',
          label: 'common.number',
          inputType: 'formInput',
        },
        postalCode: {
          inputName: 'postalCode',
          rules: 'required',
          class: 'col--12 col-md--4',
          autocomplete: false,
          type: '',
          label: 'common.postalCode',
          inputType: 'formInput',
        },
        country: {
          inputName: 'country',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          inputType: 'formSelect',
          options: 'countries',
          label: 'common.country',
        },
      },
    },
    identificationStep: {
      questionnaireEnabled: true,
    },
    identificationForm: {
      redirectToAccount: false,
      buttonText: 'dashboard.funds.myPortfolio',
    },
    settings: {
      bankAccountDetailsChangeEnabled: true,
      itemPaddingAdded: false,
      details: 'full',
      detailsChange: true,
      identification: true,
      settingsAutoReditect: 'details',
    },
    register: {
      privacyStatementUrl: 'https://www.broccoli.eu/nl/user-terms',
      enableLanguageSelect: true,
      newsletter: false,
      privacyStatement: true,
    },
    login: {
      enabled2FA: true,
    },
    authReset: {
      includePleaseCheckInboxInToast: true,
    },
    dropdownMenu: {
      dropdownMenuType: 'default',
      settingsNavigation: 'settings',
      dropdownMenuItems: {
        portfolio: {
          visible: true,
        },
        settings: {
          icon: 'cog',
          visible: true,
        },
      },
    },
    investor: {
      passportPropertyName: 'passport',
    },
    footer: {
      overridingName: 'Broccoli',
      type: 'long',
      longItems: {
        privacy: {
          url: 'https://broccoli.eu/en/privacy-statement',
          isInternal: false,
        },
        termsAndConditions: {
          url: 'https://broccoli.eu/en/general-conditions',
          isInternal: false,
        },
        cookies: {
          url: 'https://broccoli.eu/en/cookies',
          isInternal: false,
        },
        complaintsProcedure: {
          url: 'https://broccoli.eu/en/complaint-procedure',
          isInternal: false,
        },
        pricingAndPlacementProcedure: {
          url: 'https://broccoli.eu/en/pricing-and-placement-procedure',
          isInternal: false,
        },
      },
      brandLogo: 'extra-logo',
    },
  },
  emails: {
    from: 'Cyql',
    userCreatedFromBloqadmin: {
      templateId: 'd-8a0c68bdd6b5402a8d87c29584e6545b',
    },
    sendSharesEmail: {
      format: 'extended',
    },
    sharesBought: {
      format: 'full',
      templateId: {
        en: 'd-48746318611343a0bf05c7b9e9dd6fcf',
        nl: 'd-2d7fdbbd87ea428c8ddb4b88063cbac2',
      },
      enabled: true,
      signatureChoiceOnline: '',
      fromAdminTemplateId: '',
      sendlegalDocs: false,
    },
    sharesBoughtAdminNotification: {
      to: 'test@bloqhouse.com',
      templateId: '',
      enabled: false,
      signatureChoiceOnline: '',
    },
    verifyEmail: {
      enabled: true,
      templateId: {
        en: 'd-be8f16bae36043c6ba02df4e6a1e2c7e',
        nl: 'd-b539f3284d814078acbead426cd57af8',
      },
      format: 'subjectDirectorRegardsWelcomeActivate',
    },
    passwordReset: {
      enabled: true,
      templateId: {
        en: 'd-58b5df261fae4f919cca3fd4ff9891a6',
        nl: 'd-a060c2e16b9e47ae85ba8634cdfd6ed6',
      },
      format: 'directorRegardsForgotReset',
    },
    identificationRequest: {
      approved: {
        templateId: {
          en: 'd-b4785d85a6e442f5a6fd81e76a6c407d',
          nl: 'd-3a49e7a312c8445ea5f0462e3a0705bc',
        },
      },
      rejected: {
        templateId: {
          en: 'd-e141d8a608104f53a3db4cd6a6880bed',
          nl: 'd-bef649377eb145f38a4281a32a3648b2',
        },
      },
      format: 'url',
    },
    completeBidEmailSeller: {
      enabled: true,
      templateId: {
        en: 'd-23c2ff1f9cd44590b178dfd4fd5718ac',
        nl: 'd-3ab792a2123043508d0966a83967fe10',
      },
    },
    completeBidEmailBuyer: {
      enabled: true,
      templateId: {
        en: 'd-98e4ab797e7c4cb0b444dc4f6b7fe702',
        nl: 'd-456d12b3e8284f67b2d634cab1d145ee',
      },
    },
    createBid: {
      enabled: true,
      templateId: {
        en: 'd-45c90590f98a4abe9129a21c94f93dea',
        nl: 'd-12c86ee2ca8e41e3a621466fbf7146e3',
      },
    },
    negotiateBid: {
      enabled: true,
      templateId: {
        en: 'd-91015c32acdb482d8fde76456200e486',
        nl: 'd-b3e475adbc9a4c2ca8935452389820bc',
      },
    },
    requestBidPayment: {
      enabled: true,
      templateId: {
        en: 'd-2e6d6d47793947159edbfc6d9223d936',
        nl: 'd-eb594d50feef4aee9e1c1b5a669e5f8b',
      },
    },
    sepaPaymentInstructions: {
      enabled: true,
      templateId: {
        en: 'd-e7433b823e444d99b8a27033f4404b5c',
        nl: 'd-a7d8752d36494071a907b4c4110a99ae',
      },
    },
    createIdentificationRequest: {
      fromClientName: false,
      toClientName: false,
      toClientEmail: [],
    },
    translations: {
      sharesBoughtInstrument: {
        subject: 'Obligaties',
        content: 'obligatiehouder',
      },
    },
    contactSupportByEmail: {
      from: 'support+1@broccoli.eu',
      to: 'support+1@broccoli.eu',
      cc: false,
    },
    reservationEmail: {
      verified: {
        templateId: {
          en: 'd-b4be0875dccc4e7791d7b5ebd2e950a8',
          nl: 'd-9c0d341d604a47448330ad199e9878a5',
        },
      },
      unverified: {
        templateId: {
          en: 'd-c141f23679fb4ae6b897bf25e64c4f34',
          nl: 'd-72f9b382341a46cbb296f866200438c0',
        },
      },
    },
  },
};
