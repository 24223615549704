import { Module } from 'vuex';
import { State } from '@/store/models';
import { getId } from '@/helpers/utils';
import { InvestmentRepayment } from '../models/repayment';

export default {
  state: [],
  mutations: {},
  actions: {},
  getters: {
    getRepaymentsByInvestmentId:
      (state): ((investmentId: string) => unknown) =>
      (investmentId: string): InvestmentRepayment[] =>
        state.filter((investmentRepayment): boolean => getId(investmentRepayment.investment) === investmentId),
  },
} as Module<InvestmentRepayment[], State>;
