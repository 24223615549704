import { Module } from 'vuex';
import BigNumber from 'bignumber.js';
import { State } from '@/store/models';
import { Asset } from '@/store/models/asset';
import { Payment } from '@/store/models/investment';
import { getId } from '@/helpers/utils';
import { Valuation } from '../models/valuation';

export interface AssetsArray<T> extends Array<T> {
  totalLength?: number;
}

export default {
  state: [],
  mutations: {},
  actions: {},
  getters: {
    assetsLoadMore:
      (state): ((position: number, filteredAssets?: Asset[]) => Asset[]) =>
      (position: number, filteredAssets?: Asset[]): Asset[] => {
        const stateAssets = filteredAssets || state;
        const assets: AssetsArray<Asset> = stateAssets
          .filter((asset): boolean => !asset.deleted)
          .slice(0, position > stateAssets.length ? stateAssets.length : position);

        assets.totalLength = stateAssets.length;

        return assets;
      },
    getAssetById:
      (state): ((id: string) => Asset | undefined) =>
      (id: string): Asset | undefined =>
        state.find((asset): boolean => asset.id === id),
    // Get all the assets that the user has invested in (ToDo: improve 'any' typing)
    getInvestedAssets: (state, getters): Asset[] => {
      const assets: Record<string, Asset> = {};
      // Not using a map because it could return an array of undefined
      getters.getPaidOrOpenPayments.forEach((payment: Payment): void => {
        // Could be that the asset is not retrieved yet && check if we have already inserted it
        const assetId = getId(payment.asset);
        const assetValue = getters.getAssetById(assetId);
        if (assetId && !assets[assetId] && assetValue) {
          assets[assetId] = assetValue;
        }
      });
      return Object.values(assets);
    },
    getAllInvestedAssets: (state, getters): Asset[] => {
      const assets: Record<string, Asset> = {};
      getters.getPaidOpenRequestedPayments.forEach((payment: Payment): void => {
        const assetId = getId(payment.asset);
        const assetValue = getters.getAssetById(assetId);
        if (!payment.ended && assetId && !assets[assetId] && assetValue) {
          assets[assetId] = assetValue;
        }
      });
      return Object.values(assets);
    },
    getInvestedAsset:
      (state, getters): ((assetId: string) => Asset | undefined) =>
      (assetId: string): Asset | undefined =>
        state.find((): boolean => getters.getInvestedAssets.map((asset: Asset): string => asset.id!).includes(assetId)),
    getNotInvestedAssets: (state, getters): Asset[] =>
      state.filter(
        (asset): boolean => !getters.getAllInvestedAssets.map((asset: Asset): string => asset.id!).includes(asset.id),
      ),
    getAssetByInvestmentId:
      (state, getters, rootState): ((investmentId: string) => Asset | undefined) =>
      (investmentId: string): Asset | undefined =>
        state.find((asset): boolean => {
          const investment = rootState.investments.find((investment): boolean => investment.id === investmentId);

          return !!investment && investment.asset.id === asset.id;
        }),
    // Avoiding mutations
    getAssetsOrderedByCreatedDatetimeAsc: (state): Asset[] =>
      [...state].sort((a, b): number => a.createdDateTime.seconds - b.createdDateTime.seconds),
    getAssetTotalValue:
      (state, getters): ((assetId: string) => number) =>
      (assetId: string): number => {
        const asset = getters.getAssetById(assetId) as Asset | undefined;
        const valuation = getters.getActiveValuationByAsset(assetId) as Valuation | undefined;
        return new BigNumber(valuation?.sharePrice || asset?.sharePrice || 0)
          .multipliedBy(valuation?.totalValueShares || asset?.totalValueShares || 0)
          .toNumber();
      },
    getTradingAssets: (state): Asset[] => state.filter((asset): boolean => asset.tradingEnabled ?? false),
  },
} as Module<Asset[], State>;
