import { clientConfig } from '@/helpers/clientData';
/**
 * Dynamic export of the different modules depending on the whitelabel config
 */
const toExport: Record<string, unknown> = {};

const whitelabelConfig = clientConfig();
const modules = whitelabelConfig.modules;

Object.keys(modules).forEach((key): void => {
  if (modules[key]) {
    toExport[key] = require(`./${key}/routes.ts`).default;
  }
});

export default toExport;
