/**
 * Client configs.
 */

module.exports = {
  multiclient: {
    enabled: true,
    subclients: ['thephonelab', 'cyql', 'eves'],
  },
  mobilePhoneApp: false,
  currency: '€',
  logo: 'shrfunders/shrfunders.svg',
  logoAlt: 'shrfunders/shrfunders-alt.svg',
  logoShare: 'bloqhouse.png',
  logoPremium: 'shrfunders-premium.svg',
  location: 'EUROPE-WEST1',
  internalClientName: 'shrfunders',
  iconName1: 'favicon.ico',
  iconName2: 'favicon.svg',
  iconName3: 'favicon-512.png',
  scssVariables: './src/scss/variables/brands/_shrfunders.scss',
  name: 'Broccoli',
  phone: '085 048 61 04',
  email: 'support@broccoli.eu',
  website: 'https://thephonelab.nl/en/',
  glossary: true,
  langFileName: 'shrfunders/language-config.ts',
  paymentServiceProvider: 'OPP',
  oppSource: 'asset',
  bloqhouseBrandingEnabled: false,
  pescheckEnabled: true,
  modules: {
    landing: false,
    identification: {
      default: true, // setting this to false requires manual changes,
      itsme: false,
    },
  },
  landingRedirect: '/all-campaigns',
  loginRedirect: '/account',
  intercom: false,
  requireContractAgreement: true,
  requireQuestionnaire: true,
  functionality: {
    enableMeerdervoortApi: false,
    tradingBoardVersion: 'one',
    idinGlobalSwitch: false,
    containerGlobalSwitch: false,
    progressEndpoint: false,
    loanEmail: false, // THIS IS MEERDERVOORT HARDCODED. KEEP DISABLED FOR ALL CLIENTS THAT ARE NOT MEERDERVOORT
    questionnaire: {
      folderName: 'shrfunders',
    },
    checkout: {
      initCheckoutStore: 'default',
      stylingFolderName: 'default',
      remoteCheckoutEnabled: false,
      steps: [
        'Identification',
        'Questionnaire',
        'RiskQuestionnaire',
        'Investment',
        'Terms',
        'PaymentMethods',
        'Payment',
      ],
      resetCheckoutExceptWhenAt: ['Terms', 'Questionnaire'],
      enableCheckoutQuestionnaire: true,
      checkoutInvestment: {
        showWithdrawal: true,
        folderName: 'default',
        firstParagraphEnabled: false,
        showMinimumAmount: false,
      },
      checkoutLegal: {
        enableLegalDoc: false,
        footerButtonText: 'checkout.legal.accept',
        footerButtonNoteText: '',
      },
      checkoutFooter: {
        rowJustifyContentEnabled: false,
        checkoutUpdateType: 'default',
      },
      checkoutQuestionnaire: {
        folderName: 'shrfunders',
      },
      checkoutRiskQuestionnaire: {
        folderName: 'shrfunders',
      },
      checkoutRecap: {
        finalisingPaymentTextEnabled: true,
      },
      checkoutStatus: {
        statusEnabled: true,
        titleEnabled: true,
        navigateToProjectButtonEnabled: true,
        showButtons: false,
      },
    },
    paymentInitTransaction: {
      loanTypeFundMax: false,
      containsLegalTerms: false,
      paymentInit: {
        fundTypeEnabled: false,
        signatureChoiceEnabled: false,
        legalDocsEnabled: false,
        questionaireQuestionAndAnswersEnabled: false,
        knowledgeQuestionAndAnswersEnabled: false,
      },
    },
    generateDividendsEarnings: 'generateDividendsPDFBROC',
    headerForPDF: false,
    customSignUpGreeting: '',
    index: {
      preloadHref: '"https://fonts.googleapis.com/css2?family=DM+Sans:wght@300;400;500;600;700&display=swap"',
      stylesheetHref: '"https://fonts.googleapis.com/css?family=DM+Sans:wght@300;400;500;600;700&display=swap"',
    },
    dividendsFormat: true,
    dividendsReturnsOptions: {
      checkIfAssetHasFixedDividends: true,
    },
    bannerLegal: {
      type: 'text',
      requireLegalBanner: true,
      legalBannerNoRequiresLicense: true,
      legalBannerPosition: 'top',
      phoneNumber: false,
    },
    navbar: {
      boxedEnabled: false,
      languageChoice: {
        enableLanguageChoice: true,
        languages: ['nl', 'en'],
        defaultLanguage: 'nl',
      },
      externalLink: 'https://broccoli.eu/nl/blog/81/powered-by-broccoli-hoe-zit-dat-en-hoe-werkt-het',
      backgroundPrimary: false,
      containerEnabled: false,
      shadowEnabled: true,
      authPageRedirect: 'login',
      customNonLocalNonAuthLogin: false,
      logoSize: 'small',
      showDropDownMenu: true,
      navbarLinks: {
        allCampaigns: {
          visible: true,
        },
        knowledge: {
          visible: false,
          url: '',
        },
        home: {
          visible: false,
        },
      },
      navIcon: {
        homeIcon: true,
        projectsIcon: true,
      },
    },
    accountSettings: {
      salutation: true,
      initials: true,
      middleName: true,
      country: true,
    },
    accountDashboard: {
      holdingsImagePath: '@/assets/logos/shrfunders.svg',
      pendingPaymentsInfoboxEnabled: false,
    },
    accountBalance: {
      customImage: true,
      customWrapperClass: 'account__balance',
      animationsEnabled: false,
    },
    accountInvestments: {
      transactionAmountWrapperClassName: 'transactions-item-total',
      boughtSharesExcludeLoan: false,
      checkForPremium: true,
    },
    accountSingleFund: {
      availableProjectsTotal: 'available',
      textCheckFundType: false,
      displayInterest: false,
      overlayClassEnabled: false,
      totalsSectionEnabled: true,
      totalsSectionTwoEnabled: false,
      totalsSource: 'investment',
      dividendsSource: 'dividends',
      netValueCheckIfNoInvestments: false,
      includeIcon: false,
      completedBoxTextClassEnabled: true,
      premiumClassEnabled: true,
      paymentDateEarningsHistory: true,
    },
    property: {
      secondaryDocUnitedStates: false,
      secondaryDocThreshold: false,
      showContactAddress: false,
      availableNumbersEnabled: true,
      showInterestEnabled: false,
      transformedDateTimeEnabled: false,
      openCarousel: true,
      showDocsInReservation: true,
    },
    propertiesGridFund: {
      disableDocsIfNotInvested: false,
      contentsCardEnabled: true,
      subtitle: 'assetDescription',
    },
    properties: {
      toolbarSearchEnabled: false,
      dateSortingProperty: 'createdDateTime',
      titleEnabled: false,
    },
    identificationRequest: {
      imageFolderWorld: 'default/shrfunders/world.svg',
      imageFolderBusiness: 'default/shrfunders/company.svg',
      imageFolderWorldIcon: 'default/world-ico.svg',
      imageFolderBusinessIcon: 'default/company-ico.svg',
      steps: ['identification', 'questionnaire', 'riskQuestionnaire'],
      dateOfBirthFormat: 'DD/MM/YYYY',
      uploadPassport: true,
      businessRiskClassProp: false,
      businessExperienceProp: false,
      worldRiskClassProp: false,
      worldExperienceProp: false,
      businessInputs: {
        gender: {
          inputName: 'gender',
          rules: 'required',
          class: 'col--12 col-md--2',
          autocomplete: false,
          type: '',
          inputType: 'formSelect',
          options: 'genderOptions',
          label: 'common.gender',
        },
        firstName: {
          inputName: 'firstName',
          rules: 'required|alpha_spaces|max:40',
          class: 'col--12 col-md--5',
          autocomplete: true,
          type: '',
          label: 'common.firstName',
          inputType: 'formInput',
        },
        surname: {
          inputName: 'surname',
          rules: 'required|alpha_spaces|max:40',
          class: 'col--12 col-md--5',
          autocomplete: true,
          type: '',
          label: 'common.surname',
          inputType: 'formInput',
        },
        telephone: {
          inputName: 'telephone',
          rules: '',
          class: 'col--12 col-md--4',
          autocomplete: true,
          type: '',
          label: '',
          inputType: 'formTelephone',
        },
        separatedDateOfBirth: {
          inputName: 'separatedDateOfBirth',
          rules: '',
          class: '',
          autocomplete: false,
          type: '',
          inputType: 'separatedDateOfBirth',
          label: '',
        },
        placeOfBirth: {
          inputName: 'placeOfBirth',
          rules: 'required|alpha_spaces',
          class: 'col--12 col-md--6',
          autocomplete: false,
          type: '',
          label: 'common.placeOfBirth',
          inputType: 'formInput',
        },
        nationality: {
          inputName: 'nationality',
          rules: 'required',
          class: 'col--12 col-md--6',
          autocomplete: false,
          type: '',
          inputType: 'formSelect',
          options: 'countries',
          label: 'common.nationality',
        },
        address: {
          inputName: 'address',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'common.street',
          inputType: 'formInput',
        },
        houseNumber: {
          inputName: 'houseNumber',
          rules: 'required',
          class: 'col--12 col-md--4',
          autocomplete: false,
          type: '',
          label: 'common.number',
          inputType: 'formInput',
        },
        city: {
          inputName: 'city',
          rules: 'required',
          class: 'col--12 col-md--4',
          autocomplete: false,
          type: '',
          label: 'common.city',
          inputType: 'formInput',
        },
        postalCode: {
          inputName: 'postalCode',
          rules: 'required',
          class: 'col--12 col-md--4',
          autocomplete: false,
          type: '',
          label: 'common.postalCode',
          inputType: 'formInput',
        },
        country: {
          inputName: 'country',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          inputType: 'formSelect',
          options: 'countries',
          label: 'common.country',
        },
        companyName: {
          inputName: 'companyName',
          rules: 'required',
          class: 'col--12 col-md--6',
          autocomplete: false,
          type: '',
          label: 'common.companyName',
          inputType: 'formInput',
        },
        director: {
          inputName: 'director',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'common.nameOfDirector',
          inputType: 'formInput',
        },
        kvkNumber: {
          inputName: 'kvkNumber',
          rules: 'required|integer',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'identification.business.kvkNumber',
          inputType: 'formInput',
        },
        kvkImage: {
          inputName: 'kvkImage',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          inputType: 'formFileInput',
          label: 'identification.business.uploadYourKvKImage',
        },
        passport: {
          inputName: 'passport',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          inputType: 'formFileInput',
          label: 'identification.world.uploadYourPassport',
        },
      },
      worldInputs: {
        gender: {
          inputName: 'gender',
          rules: 'required',
          class: 'col--12 col-md--2',
          autocomplete: false,
          type: '',
          inputType: 'formSelect',
          options: 'genderOptions',
          label: 'common.gender',
        },
        firstName: {
          inputName: 'firstName',
          rules: 'required|alpha_spaces|max:40',
          class: 'col--12 col-md--5',
          autocomplete: true,
          type: '',
          label: 'common.firstName',
          inputType: 'formInput',
        },
        surname: {
          inputName: 'surname',
          rules: 'required|alpha_spaces|max:40',
          class: 'col--12 col-md--5',
          autocomplete: true,
          type: '',
          label: 'common.surname',
          inputType: 'formInput',
        },
        telephone: {
          inputName: 'telephone',
          rules: '',
          class: 'col--12 col-md--4',
          autocomplete: true,
          type: '',
          label: 'common.telephone',
          inputType: 'formTelephone',
        },
        separatedDateOfBirth: {
          inputName: 'separatedDateOfBirth',
          rules: '',
          class: '',
          autocomplete: false,
          type: '',
          inputType: 'separatedDateOfBirth',
          label: '',
        },
        placeOfBirth: {
          inputName: 'placeOfBirth',
          rules: 'required|alpha_spaces',
          class: 'col--12 col-md--6',
          autocomplete: false,
          type: '',
          label: 'common.placeOfBirth',
          inputType: 'formInput',
        },
        nationality: {
          inputName: 'nationality',
          rules: 'required',
          class: 'col--12 col-md--6',
          autocomplete: false,
          type: '',
          inputType: 'formSelect',
          options: 'countries',
          label: 'common.nationality',
        },
        city: {
          inputName: 'city',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'common.city',
          inputType: 'formInput',
        },
        address: {
          inputName: 'address',
          rules: 'required',
          class: 'col--12 col-md--4',
          autocomplete: false,
          type: '',
          label: 'common.address',
          inputType: 'formInput',
        },
        houseNumber: {
          inputName: 'houseNumber',
          rules: 'required',
          class: 'col--12 col-md--4',
          autocomplete: false,
          type: '',
          label: 'common.number',
          inputType: 'formInput',
        },
        postalCode: {
          inputName: 'postalCode',
          rules: 'required',
          class: 'col--12 col-md--4',
          autocomplete: false,
          type: '',
          label: 'common.postalCode',
          inputType: 'formInput',
        },
        country: {
          inputName: 'country',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          inputType: 'formSelect',
          options: 'countries',
          label: 'common.country',
        },
      },
    },
    identificationStep: {
      questionnaireEnabled: true,
    },
    identificationForm: {
      redirectToAccount: false,
      buttonText: 'dashboard.funds.myPortfolio',
    },
    settings: {
      itemPaddingAdded: false,
      details: 'full',
      detailsChange: true,
      identification: true,
      settingsAutoReditect: 'details',
    },
    register: {
      privacyStatementUrl: 'https://www.broccoli.eu/nl/user-terms',
      enableLanguageSelect: true,
      newsletter: false,
      privacyStatement: true,
    },
    login: {
      enabled2FA: true,
    },
    authReset: {
      includePleaseCheckInboxInToast: true,
    },
    dropdownMenu: {
      dropdownMenuType: 'default',
      settingsNavigation: 'settings',
      dropdownMenuItems: {
        portfolio: {
          visible: true,
        },
        settings: {
          icon: 'cog',
          visible: true,
        },
      },
    },
    investor: {
      passportPropertyName: 'passport',
    },
    footer: {
      type: 'long',
      longItems: {
        privacy: {
          url: 'https://broccoli.eu/en/privacy-statement',
          isInternal: false,
        },
        termsAndConditions: {
          url: 'https://broccoli.eu/en/general-conditions',
          isInternal: false,
        },
        cookies: {
          url: 'https://broccoli.eu/en/cookies',
          isInternal: false,
        },
        complaintsProcedure: {
          url: 'https://broccoli.eu/en/complaint-procedure',
          isInternal: false,
        },
        pricingAndPlacementProcedure: {
          url: 'https://broccoli.eu/en/pricing-and-placement-procedure',
          isInternal: false,
        },
      },
      brandLogo: 'extra-logo',
    },
  },
  emails: {
    from: 'ThePhoneLab',
    userCreatedFromBloqadmin: {
      enabled: true,
      templateId: 'd-8a0c68bdd6b5402a8d87c29584e6545b',
    },
    sendSharesEmail: {
      format: 'extended',
    },
    sharesBought: {
      format: 'full',
      templateId: {
        en: 'd-6d279977631446aeb4b52b1ba659d8f4',
        nl: 'd-992ef63fbc294df8afec5eb4e3a13446',
      },
      enabled: true,
      signatureChoiceOnline: '',
      fromAdminTemplateId: '',
      sendlegalDocs: false,
      bcc: ['hello+1@broccoli.eu'],
    },
    sharesBoughtAdminNotification: {
      to: 'test@bloqhouse.com',
      templateId: '',
      enabled: false,
      signatureChoiceOnline: '',
    },
    verifyEmail: {
      enabled: true,
      templateId: {
        en: 'd-72be9f4bda2947c49ca9df01b56026e5',
        nl: 'd-dcfbb3e8ecef49fd9d04e4d02fa3a4b2',
      },
      format: 'subjectDirectorRegardsWelcomeActivate',
    },
    passwordReset: {
      enabled: true,
      templateId: {
        en: 'd-8a0c68bdd6b5402a8d87c29584e6545b',
        nl: 'd-1f1f7d8907a444d280e52b5f47abc9d1',
      },
      format: 'directorRegardsForgotReset',
    },
    identificationRequest: {
      approved: {
        enabled: true,
        templateId: {
          en: 'd-d64bc03f2a5b4e2dbcfb8137c232a1af',
          nl: 'd-4382224424db471d832e2e2d10b147b3',
        },
      },
      rejected: {
        enabled: true,
        templateId: {
          en: 'd-ca956afe41ff4a2b929ca38db7040014',
          nl: 'd-2d6e55ad8a974b939603277e67fd34c0',
        },
      },
      format: 'url',
    },
    completeBidEmailSeller: {
      enabled: true,
      templateId: {
        en: 'd-cdb6ccb11e4c4c24bc1980079f6583ca',
        nl: 'd-51eb858760bc4241b14c55cf6490d2e7',
      },
    },
    completeBidEmailBuyer: {
      enabled: true,
      templateId: {
        en: 'd-5a5895dd577549298d453d4e53e5994a',
        nl: 'd-bea04c5953da4439bc690ed6e168b6e7',
      },
    },
    createBid: {
      enabled: true,
      templateId: {
        en: 'd-55ab55ab1e3140889cbe8c0a3fff715c',
        nl: 'd-12c86ee2ca8e41e3a621466fbf7146e3',
      },
    },
    negotiateBid: {
      enabled: true,
      templateId: {
        en: 'd-65215ef979df4fa592b42bf93eafb751',
        nl: 'd-4af7e829d49a40ac82702036d8125480',
      },
    },
    requestBidPayment: {
      enabled: true,
      templateId: {
        en: 'd-a3d9bd29af2e4bb9b1ca5593279b1ab1',
        nl: 'd-1bea55eb521344eeb851f1c130d4bc97',
      },
    },
    sepaPaymentInstructions: {
      enabled: true,
      templateId: {
        en: 'd-b1bc30f2965d4bdabc7a221222548922',
        nl: 'd-22e8dceed5e14ffa83ce2b43a9bd81c7',
      },
    },
    createIdentificationRequest: {
      fromClientName: false,
      toClientName: false,
      toClientEmail: [],
    },
    translations: {
      sharesBoughtInstrument: {
        subject: 'Obligaties',
        content: 'obligatiehouder',
      },
    },
    contactSupportByEmail: {
      from: 'support+1@broccoli.eu',
      to: 'support+1@broccoli.eu',
      cc: false,
    },
    reservationEmail: {
      verified: {
        templateId: {
          en: 'd-624fbf0059d74fce8719045606ccd79e',
          nl: 'd-53afcdc06c0746a78c833feb27c795d2',
        },
      },
      unverified: {
        templateId: {
          en: 'd-d0465a76dd9d445a8a09d6b3bc9e755c',
          nl: 'd-3061912cd7084272ac20ee4f285779df',
        },
      },
    },
  },
};
