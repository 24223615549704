import Vue from 'vue';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/compat/storage';

const config: {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
} = {
  apiKey: process.env.VUE_APP_BLOQIFY_FIREBASE_API_KEY!,
  authDomain: process.env.VUE_APP_BLOQIFY_FIREBASE_AUTH_DOMAIN!,
  databaseURL: process.env.VUE_APP_BLOQIFY_FIREBASE_DATABASE_URL!,
  projectId: process.env.VUE_APP_BLOQIFY_FIREBASE_PROJECT_ID!,
  storageBucket: process.env.VUE_APP_BLOQIFY_FIREBASE_STORAGE_BUCKET!,
  messagingSenderId: process.env.VUE_APP_BLOQIFY_FIREBASE_MESSAGING_SENDER_ID!,
  appId: process.env.VUE_APP_BLOQIFY_FIREBASE_APP_ID!,
  measurementId: process.env.VUE_APP_BLOQIFY_FIREBASE_MEASUREMENT_ID!,
};

const firebaseApp = firebase.initializeApp(config);
const auth = firebaseApp.auth();
const storage = firebaseApp.storage();
const db = firebaseApp.firestore();

// Special region for local test
const functions: firebase.functions.Functions = firebaseApp.functions(
  process.env.VUE_APP_BLOQIFY_FIREBASE_CLOUD_FUNCTIONS_REGION,
);

// Emulator for local tests
if (process.env.NODE_ENV === 'development') {
  functions.useEmulator('localhost', 5002);
  db.useEmulator('localhost', 8081);
  auth.useEmulator('http://localhost:9099');
  storage.useEmulator('localhost', 9199);
}

// Injecting firebase to Vue's context
Vue.prototype.$firestore = db;
Vue.prototype.$storage = storage;
Vue.prototype.$auth = auth;

// Types
declare module 'vue/types/vue' {
  interface Vue {
    $firestore: firebase.firestore.Firestore;
    $storage: firebase.storage.Storage;
    $auth: firebase.auth.Auth;
  }
}

export { db, auth, functions, storage, firebase, firebaseApp };
