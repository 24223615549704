import { Vue, Component } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class RefreshModal extends Vue {
  onClick(): void {
    this.$emit('click-on-refresh');
  }
}
