import VueI18n from 'vue-i18n';
import Vue from 'vue';
import { clientConfig } from '@/helpers/clientData';
import deepmerge from 'deepmerge';

Vue.use(VueI18n);

const whitelabelConfig = clientConfig();
const languageChoice = whitelabelConfig.functionality.navbar.languageChoice;
const languagesConfig = languageChoice.languages;
const defaultLanguageConfig = languageChoice.defaultLanguage;

export type Language = (typeof languagesConfig)[number] | 'nl' | 'en';
export const languages: Language[] = languagesConfig || ['nl', 'en'];

export const defaultLanguage: Language = defaultLanguageConfig || 'nl';
export const localStorageKey = 'vue-i18n-language';

export const isValidLang = (lang): lang is Language => languages.includes(lang);

export const i18n = new VueI18n({
  silentTranslationWarn: true,
  locale: defaultLanguage, // set locale
  fallbackLocale: 'nl',
  messages: {}, // set locale messages
  dateTimeFormats: {
    en: {
      expected: {
        year: 'numeric',
        month: 'long',
      },
    },
    nl: {
      expected: {
        year: 'numeric',
        month: 'long',
      },
    },
    de: {
      expected: {
        year: 'numeric',
        month: 'long',
      },
    },
  },
  numberFormats: {
    en: {
      decimal: {
        style: 'decimal',
        minimumFractionDigits: 2,
      },
    },
    nl: {
      decimal: {
        style: 'decimal',
        minimumFractionDigits: 2,
      },
    },
    de: {
      decimal: {
        style: 'decimal',
        minimumFractionDigits: 2,
      },
    },
  },
});

const isAppleWebkit = navigator.userAgent.includes('AppleWebKit');
const internalClientName = whitelabelConfig?.internalClientName;

/**
 * Paralelly import the default language file and the project language file
 */
const getCombinatedLangFile = async (
  lang: string,
  additionalProperties?: unknown,
): Promise<Record<string, unknown>> => {
  const languageFiles = [
    import(/* webpackChunkName: "lang-default-[request]" */ `@/lang/default/${lang}.ts`),
    import(/* webpackChunkName: "lang-project-[request]" */ `@/lang/${internalClientName}/${lang}.ts`),
  ];
  const [defaultLanguageFile, projectLanguageFile] = await Promise.all(languageFiles);
  if (!additionalProperties) {
    return deepmerge(defaultLanguageFile.default[lang], projectLanguageFile.default[lang]);
  }

  const mergedDefault = {
    ...defaultLanguageFile.default[lang],
    // @ts-expect-error - ToDo: fix this error
    assetTypes: additionalProperties.assetTypes[lang],
  };

  return deepmerge(mergedDefault, projectLanguageFile.default[lang]);
};

/**
 * Setting up the default language
 */
void (async (): Promise<void> => {
  const combination = await getCombinatedLangFile(defaultLanguage);
  // @ts-expect-error - ToDo: fix this error
  i18n.setLocaleMessage(defaultLanguage, isAppleWebkit ? Object.freeze(combination) : combination);
})();

// Lazy loading of different language
export async function loadLanguageAsync(lang: Language, additionalProperties?: unknown): Promise<boolean> {
  // Retrigger this if additionalProperties are passed which happens in App when the admin assetType translations are loaded
  if (i18n.locale !== lang || additionalProperties) {
    if (!i18n.messages[lang] || additionalProperties) {
      let combination;
      if (!additionalProperties) {
        combination = await getCombinatedLangFile(lang);
      } else {
        combination = await getCombinatedLangFile(lang, additionalProperties);
      }
      i18n.setLocaleMessage(lang, combination);
    }
    i18n.locale = lang;
  }
  localStorage.setItem(localStorageKey, lang);
  return true;
}
