import { Module } from 'vuex';
import { State } from '@/store/models';
import { IdentificationRequestStatus, IdentificationRequest, isBusiness } from '@/store/models/identificationRequest';
import { KYCMethods } from '@/store/models/user';

export default {
  getters: {
    /**
     * Helper to detect what kind of iR we are facing.
     */
    identificationRequestType: (state): KYCMethods.Private | KYCMethods.Business =>
      state.name ? KYCMethods.Private : KYCMethods.Business,
    /**
     * Determines whether a manual approval in Bloqadmin is a prerequisite for investment.
     */
    canInvestWithoutApproval: (state, getters, rootState): boolean => {
      const { identificationSettings, identificationRequest } = rootState;

      // Check if settings are loaded
      if (identificationSettings === null) {
        return false;
      }

      const { business: businessRequiresApproval, private: privateRequiresApproval } =
        identificationSettings.requireApproval;

      // If there is no (custom) identification request sent or it has been removed, then return
      if (!identificationRequest || identificationRequest.deleted) {
        return false;
      }

      const isIrBusiness = isBusiness(identificationRequest);

      // Check if:
      // - there was an identification request sent (TODO: add idin)
      // - it is not rejected
      // - that identification method doesn't require approval
      return (
        identificationRequest.status !== IdentificationRequestStatus.Rejected &&
        ((isIrBusiness && !businessRequiresApproval) || (!isIrBusiness && !privateRequiresApproval))
      );
    },
    /**
     * First checks if the investor is identified (e.g. as a result of iDIN) and then if an approval is required.
     */
    isEligibleToInvest: (state, getters): boolean => getters.isInvestor || getters.canInvestWithoutApproval,
  },
} as Module<IdentificationRequest, State>;
