import to from 'await-to-js';
import firebase from 'firebase/compat';
import Vue from 'vue';
import { ActionContext, Module } from 'vuex';
import { Project } from '@/store/models/project';
import { InitialStateSlice, State, StateSlice } from '@/store/models';
import { db } from '@/firebase';

const GET_PROJECT_ERROR = 'GET_LOANS_ERROR';
const GET_PROJECT_SUCCESS = 'GET_LOANS_SUCCESS';
const GET_PROJECT_PROCESSING = 'GET_LOANS_PROCESSING';

export default {
  state: new InitialStateSlice(),
  mutations: {
    [GET_PROJECT_ERROR](state: StateSlice, error: Error): void {
      Vue.set(state, 'status', 'error');
      Vue.set(state, 'error', error.message || 'Something went wrong');
    },
    [GET_PROJECT_SUCCESS](state: StateSlice, payload: unknown): void {
      Vue.set(state, 'status', 'success');
      Vue.set(state, 'payload', payload);
    },
    [GET_PROJECT_PROCESSING](state: StateSlice): void {
      Vue.set(state, 'status', 'processing');
      Vue.set(state, 'payload', null);
    },
  },
  actions: {
    async getProjectsByAsset({ commit }: ActionContext<StateSlice, State>, { id }: { id: string }): Promise<void> {
      commit(GET_PROJECT_PROCESSING);

      const [getProjectError, getProjectSuccess] = await to(
        db.collection('assets').doc(id).collection('projects').where('deleted', '==', false).get(),
      );

      if (getProjectError) {
        return commit(GET_PROJECT_ERROR, getProjectError);
      }

      const projects = getProjectSuccess?.docs.map(
        (doc): firebase.firestore.DocumentData => ({
          ...doc.data(),
          id: doc.id,
        }),
      );

      return commit(GET_PROJECT_SUCCESS, projects);
    },
  },
} as Module<StateSlice<Project[]>, State>;
