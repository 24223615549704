import { Module } from 'vuex';
import { State } from '@/store/models';
import to from 'await-to-js';
import { functions, firebase } from '@/firebase';

interface CustomLoginState {
  comesFromMobile: boolean;
}

export default {
  state: {
    comesFromMobile: localStorage.getItem('comesFromMobile') === 'true' || false,
  },
  mutations: {
    setTrue(state): void {
      state.comesFromMobile = true;
      localStorage.setItem('comesFromMobile', 'true');
    },
  },
  actions: {
    async LoginwithCustomToken({ commit }, { clientIdToken }: { clientIdToken: string }): Promise<void> {
      if (!clientIdToken) {
        return;
      }
      const [createCustomLoginTokenError, createCustomLoginTokenSuccess] = await to(
        functions.httpsCallable('createCustomLoginToken')({ clientIdToken }),
      );
      if (createCustomLoginTokenError || !createCustomLoginTokenSuccess) {
        throw new Error('token error');
      }
      const [error] = await to(firebase.auth().signInWithCustomToken(createCustomLoginTokenSuccess.data));
      if (error) {
        console.error('Authentication error:', error);
        return;
      }

      commit('setTrue');
    },
  },
  getters: {
    userComesFromMobile: (state): boolean => state.comesFromMobile,
  },
} as Module<CustomLoginState, State>;
