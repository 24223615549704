import { Module } from 'vuex';
import { State } from '@/store/models';
import { InvestmentCost } from '../models/cost';

export default {
  state: [],
  mutations: {},
  actions: {},
  getters: {
    getCostsByInvestmentId:
      (state): ((investmentId: string) => InvestmentCost[]) =>
      (investmentId: string): InvestmentCost[] =>
        state.filter((investmentCost): boolean => investmentCost.investment.id === investmentId),
  },
} as Module<InvestmentCost[], State>;
